/**
 * @flow
 * */
class DateTimeFormatter {
  /**
     * buildDate
     * @param {String | Date} date -
     * @param {Boolean} withDay -
     * @returns {String} -  Aug 6, 2007
     */
  static buildDate(date: Date | string, withDay: boolean = true) {
    let _date: Date = new Date();
    if (date instanceof Date) _date = date;
    if (typeof date === 'string') _date = new Date(date);
    if (!date) throw new Error('The parameter "dob" should be a string or an instance of Date');

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    return `${months[_date.getMonth()]}${withDay ? ` ${_date.getDate()}, ` : ' '}${_date.getFullYear()}`;
  }
}
// export const nowUtcFormatter = () => {
//   const date = new Date();
//   return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
//     date.getUTCDate(), date.getUTCHours(),
//     date.getUTCMinutes(), date.getUTCSeconds());
// };

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export const nowUtcFormatter = () => {
  const nowFormat = `${[
    new Date().getFullYear(),
    padTo2Digits(new Date().getUTCMonth() + 1),
    padTo2Digits(new Date().getUTCDate())
  ].join('-')
  } ${
    [
      padTo2Digits(new Date().getUTCHours()),
      padTo2Digits(new Date().getUTCMinutes()),
      padTo2Digits(new Date().getUTCSeconds())
    ].join(':')}`;

  return new Date(nowFormat);
};

export default DateTimeFormatter;
