/* eslint-disable  no-mixed-operators */
/**
 * @flow
 * */
import type { PlayerState } from 'modules/SRMT/Players/types';
import { getProjectState } from 'modules/AML/index';
import { createSelector } from 'reselect';

/**
 * amlCommon -
 * @param {Object} state -
 * @returns {Function} -
 */
export const amlCommon = (state: Object) => getProjectState(state).common || {};

/**
 * amlDashboard -
 * @param {Object} state -
 * @returns {Function} -
 */
export const amlDashboard = (state: Object) => getProjectState(state).dashboard || {};

/**
 * amlAlerts -
 * @param {Object} state -
 * @returns {Function} -
 */
export const amlAlerts = (state: Object) => getProjectState(state).alerts || {};

/**
 * amlReports -
 * @param {Object} state -
 * @returns {Function} -
 */
export const amlReports = (state: Object) => getProjectState(state).reports || {};

/**
 * amlPlayers -
 * @param {Object} state -
 * @returns {Function} -
 */
export const amlPlayers = (state: Object) => getProjectState(state).players || {};

/**
 * amlBoxesSelector-
 * @returns {Function} -
 */
export const amlBoxesSelector = createSelector(
  amlCommon,
  (state) => state.boxes || {}
);

/**
 * amlDashboardSelector-
 * @returns {Function} -
 */
export const amlDashboardSelector = createSelector(
  amlDashboard,
  (state) => state.dashboardCharts || {}
);

/**
 * amlTransactionsSelector-
 * @returns {Function} -
 */
export const amlTransactionsSelector = createSelector(
  amlCommon,
  (state) => state.transactions || {}
);

/**
 * amlPlayersSelector-
 * @returns {Function} -
 */
export const amlPlayersSelector = createSelector(
  amlPlayers,
  (state) => state.players || {}
);

/**
 * amlPlayerAlerts -
 * @param {Object} state -
 * @returns {Function} -
 */
export const amlPlayerAlertsSelector = createSelector(
  amlPlayers,
  (state) => state.playerAlerts || {}
);

/**
 * amlPlayerAlertsBulkReviewSelector -
 * @param {Object} state -
 * @returns {Function} -
 */
export const amlPlayerAlertsBulkReviewSelector = createSelector(
  amlPlayers,
  (state) => state.playerAlertsBulkReview || {}
);

/**
 * amlConfigsSelector-
 * @returns {Function} -
 */
export const amlConfigsSelector = createSelector(
  amlCommon,
  (state) => state.configs || {}
);

/**
 * amlPlayerScoreRulesSelector-
 * @returns {Function} -
 */
export const amlPlayerScoreRulesSelector = createSelector(
  amlPlayers,
  (state) => state.playerScoreRules || {}
);

/**
 * amlPlayerScoreCountSelector-
 * @returns {Function} -
 */
export const amlPlayerScoreCountSelector = createSelector(
  amlPlayers,
  (state) => state.scoreCount || {}
);

/**
 * amlPlayerScoreRulesActionSelector-
 * @returns {Function} -
 */
export const amlPlayerScoreRulesActionSelector = createSelector(
  amlPlayers,
  (state) => state.playerScoreRulesAction || {}
);

/**
 * amlPlayerBlockHistoryReportSelector-
 * @returns {Function} -
 */
export const amlPlayerBlockHistoryReportSelector = createSelector(
  amlReports,
  (state) => state.playerBlockHistoryReport || {}
);

/**
 * amlPlayerScoreReportSelector-
 * @returns {Function} -
 */
export const amlPlayerScoreReportSelector = createSelector(
  amlReports,
  (state) => state.playerScoreReport || {}
);

/**
 * amlRuleScoreReportSelector-
 * @returns {Function} -
 */
export const amlRuleScoreReportSelector = createSelector(
  amlReports,
  (state) => state.amlRuleScoreReport || {}
);

/**
 * amlPlayerMultiAccountSelector-
 * @returns {Function} -
 */
export const amlPlayerMultiAccountSelector = createSelector(
  amlPlayers,
  (state) => state.playerMultiAccounts || {}
);

/**
 * amlPlayerRuleScoreHistory-
 * @returns {Function} -
 */
export const amlPlayerRuleScoreHistory = createSelector(
  amlPlayers,
  (state) => state.playerRuleScoreHistory || {}
);

/**
 * amlAlertsSelector -
 * @param {String} tabKey -
 * @returns {Function} -
 */
export const amlAlertsSelector = ({ tabKey }: Object) => createSelector(
  amlAlerts,
  (state) => state[tabKey] && state[tabKey].alerts || {}
);

/**
 * amlAlertExpandDataSelector -
 * @param {String} tabKey -
 * @returns {Function} -
 */
export const amlAlertExpandDataSelector = ({ tabKey, alertId }: Object) => createSelector(
  amlAlerts,
  state => state[tabKey] && state[tabKey]?.alerts?.data?.find(e => e.AlertId === alertId)?.expand || {}
);

/**
 * amlReviewHistoryExpand -
 * @param {String} tabKey -
 * @param {Number} alertHistoryId -
 * @returns {Function} -
 */
export const amlReviewHistoryExpand = ({ tabKey, alertHistoryId }: Object) => createSelector(
  amlAlerts,
  state => state[tabKey] && state[tabKey]?.reviewing_alert?.history?.data?.find(e => e.AlertHistoryId === alertHistoryId)?.expand || {}
);

/**
 * amlAlertsExportSelector -
 * @param {String} tabKey -
 * @returns {Function} -
 */
export const amlAlertsExportSelector = ({ tabKey }: Object) => createSelector(
  amlAlerts,
  (state) => state[tabKey] && state[tabKey].export_state || {}
);

/**
 * amlAlertsReviewSelector -
 * @param {String} tabKey -
 * @returns {Function} -
 */
export const amlAlertsReviewSelector = ({ tabKey } : Object) => createSelector(
  amlAlerts,
  (state) => state[tabKey] && state[tabKey].reviewing_alert || {}
);

/**
 * amlAlertsReviewEditSelector -
 * @param {String} tabKey -
 * @returns {Function} -
 */
export const amlAlertsReviewEditSelector = ({ tabKey } : Object) => createSelector(
  amlAlerts,
  (state) => state[tabKey] && state[tabKey].reviewing_alert.edit || {}
);

/**
 * amlAlertsReviewEditSelector -
 * @param {String} tabKey -
 * @returns {Function} -
 */
export const amlAlertsBulkReviewEditSelector = ({ tabKey }: Object) => createSelector(
  amlAlerts,
  (state) => state[tabKey] && state[tabKey].bulk_review || {}
);

/**
 * amlAlertsBulkReviewHistorySelector -
 * @param {String} tabKey -
 * @returns {Function} -
 */
export const amlAlertsBulkReviewHistorySelector = ({ tabKey }: Object) => createSelector(
  amlAlerts,
  (state) => state[tabKey] && state[tabKey].reviewing_alert.history || {}
);

/**
 * amlRiskCategoryHistorySelector -
 * @param {String} tabKey -
 * @returns {Function} -
 */
export const amlRiskCategoryHistorySelector = ({ tabKey }: Object) => createSelector(
  amlAlerts,
  (state) => state[tabKey] && state[tabKey].risk_category_history || {}
);

/**
 * playerAnalyticsSelector -
 * @param {String} type -
 * @returns {Function} -
 */
export const playerAnalyticsSelector = ({ type }:Object) => createSelector(
  amlPlayers,
  (state) => state && state[type] || {}
);

/**
 * addFiles selector
 * @return {Function} -
 */
export const addFilesAMLSelector = createSelector(
  amlPlayers,
  (state: PlayerState) => state && state.addFiles || {}
);

/**
 * getFiles selector
 * @return {Function} -
 */
export const getFilesAMLSelector = createSelector(
  amlPlayers,
  (state: PlayerState) => state && state.getFiles || {}
);

/**
 * deleteFiles selector
 * @return {Function} -
 */
export const deleteFilesAMLSelector = createSelector(
  amlPlayers,
  (state: PlayerState) => state && state.deleteFiles || {}
);
