/**
 * @flow
 * */
import React from 'react';
import Paper from '@geneui/components/Paper';
import Radio from '@geneui/components/Radio';

import { paperConfig } from 'config/settings/core-ui-strings';

import './styles.scss';

type Props = {
    setSelected: Function,
    isSelected: boolean,
    textSize: number,
    title: string,
    id: string
};

/**
 *
 * @param {function} setSelected - set Selected
 * @param {bool} isSelected - Is Selected
 * @param {number} textSize - Text Font Size
 * @param {string} title - View Mode Title
 * @param {string} id - View Mode Identifier
 * @returns {JSX.Element} - React.Component
 */
const AppSettingsPopupViewModeItem = ({ setSelected, isSelected, textSize, title, id }: Props) => (
  <div className="view-mode-item-container">
    <Paper
      style={{ fontSize: textSize, borderColor: isSelected ? '#1473e6' : 'inherit' }}
      cornerRadius={paperConfig.cornerRadius.fullRadius}
      className="view-mode-item-paper"
      border
    >Aa
    </Paper>
    <Radio
      className="view-mode-item-radio"
      onChange={() => setSelected(id)}
      checked={isSelected}
      label={title}
    />
  </div>
);

AppSettingsPopupViewModeItem.displayName = 'AppSettingsPopupViewModeItem';

export default AppSettingsPopupViewModeItem;
