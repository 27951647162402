/**
 * @flow
 * */

export const REQUEST_METHODS = {
  DELETE: 'delete',
  PATCH: 'patch',
  POST: 'post',
  PUT: 'put',
  GET: 'get'
};

export type MinimalReducer = {
    status: ?string,
    error: ?string,
}

export type GameStyleInfoChunk = { limit: number, delay: number };

export type GameStyleCategoryModel = {|
    Name: string,
    Delay: number,
    Limit: number,
    SubId: ?number,
    Priority: number,
    IsSelected: boolean,
    PlayerSubCategories: Array<GameStyleCategoryModel>
|};

export type FilterType = {|
    filters: Object
|};

export type FilterAndTabKeyType = {|
    filters: Object,
    tabKey: string
|};

export type WithTotalCount<T> = {
    Models: Array<T>,
    TotalCount: number
};

export type WithTotalCountAndData<T> = {
    Data: WithTotalCount<T>
};
export type WithData<T> = {
    Data?: T,
    data?: T,
    totalCount?: number
};

export type GlobalKPIsModel = {
    AvgBets: number,
    AvgPrice: number,
    CalcBets: number,
    ChildLevelId: number,
    CompetitionId: number,
    Count: number,
    Name: string,
    NameId: number,
    ParentKey: string,
    PercentofProfite: number,
    Profit: number,
    RegionId: number,
    SportId: number,
    TurnOver: number,
    Type: string
};

export type hourlyActivityModel = {
    AverageBets: number,
    CountOfRounds: number
};

export type GameStyleCategoryGroupModel = Array<GameStyleCategoryModel>

export type RMTApiResponseModel = {|
    status: number,
    Data: any,
    HasError: boolean,
    AlertType: string,
    AlertMessage: string,
    ModelErrors: any
|}

export type AppSettingsViewModeType = {|
    key: string,
    size: number,
    title: string
|}

export type AppSettingsSuggestionViewModeType = {|
    key: string,
    size: number,
    title: string,
    disabled: boolean
|}

export type AppSettingsType = {|
    viewMode: string,
    language: string
|}

export type AppSettingsState = {|
    appSettings: {|
        ...MinimalReducer,
        data: AppSettingsType,
        update: {|
            ...MinimalReducer
        |}
    |}
|};

export const GroupCreationType = {
  player: 'player',
  multiAccount: 'multiAccount'
};
