import { AlertChart, AnalyticChart } from '../types';

export const generateCategoriesChart = (data) => {
  if (data == null || !data.length) return;
  const alldates = data.map(res => res.Date).reverse();
  const { length } = data[0].array;
  const allvalues = [];
  for (let index = 0; index < length; index++) {
    allvalues[index] = data.map(res => res.array[index].Count).reverse();
  }

  const changeCategoryDates = [];
  alldates.forEach((res) => {
    const jsdate = new Date(res);
    const newDate = `${jsdate.getMonth() + 1}/${jsdate.getDate()}/${jsdate.getFullYear()}`;
    changeCategoryDates.push(newDate);
  });

  const options = {
    categories: changeCategoryDates,
    data: [
      {
        name: 'Arbitrage Betting',
        data: allvalues[0],
        color: '#3f51b5'
      },
      {
        name: 'SFM',
        data: allvalues[1],
        color: '#ff4081'
      },
      {
        name: 'Corridor',
        data: allvalues[2],
        color: '#1c921c'
      },
      {
        name: 'Late Betting',
        data: allvalues[3],
        color: '#ff5252'
      },
      {
        name: 'Strong Opinion',
        data: allvalues[4],
        color: '#ff9800'
      },
      {
        name: 'Bot Arbitrage',
        data: allvalues[5],
        color: '#7b3db9'
      },
      {
        name: 'Bonus Hunter',
        data: allvalues[6],
        color: '#902f0c'
      }
    ]
  };

  // eslint-disable-next-line consistent-return
  return options;
};

export const generateGSTendency = (data) => {
  if (data == null || !data.length) return;
  const alldates = data.map(res => res.Date).reverse();
  const GSPlayers = data.map(res => res.GSPlayers).reverse();
  const TotalPlayers = data.map(res => res.TotalPlayers).reverse();

  const changeCategoryDates = [];
  alldates.forEach((res) => {
    const jsdate = new Date(res);
    const newDate = `${jsdate.getMonth() + 1}/${jsdate.getDate()}/${jsdate.getFullYear()}`;
    changeCategoryDates.push(newDate);
  });

  // const body = {
  //   categories: [
  //     'January',
  //     'February',
  //     'March'
  //   ],
  //   data: [
  //     {
  //       color: '#9e90ed',
  //       fillOpacity: 0.3,
  //       type: 'areaspline',
  //       data: [65, 10, 100],
  //       name: 'Channel 1'
  //     },
  //     {
  //       color: '#4f96eb',
  //       data: [30, 18, 33],
  //       name: 'Channel 2'
  //     }
  //   ]
  // };
  const options = {
    categories: changeCategoryDates,
    data: [
      {
        name: 'Game Style Players',
        data: GSPlayers,
        color: '#ff5252',
        fillOpacity: 0.3,
        type: 'areaspline'
      },
      {

        name: 'Total Players',
        color: 'rgba(56, 126, 167, 0.45)',
        fillOpacity: 0.3,
        type: 'areaspline',
        data: TotalPlayers,
        yAxis: 1
      }
    ]
  };

  // eslint-disable-next-line consistent-return
  return options;
};

export const analyticChartConfig = {
  [AnalyticChart.additional]: {
    name: 'Additional Category',
    tooltip: 'The chart displays the real-time distribution of players with Additional categories.',
    categoryType: 3
  },
  [AnalyticChart.financial]: {
    name: 'Financial Category',
    tooltip: 'The chart displays the real-time distribution of players with Financial categories.',
    categoryType: 1
  },
  [AnalyticChart.gameStyle]: {
    name: 'Game Style Category',
    tooltip: 'The chart displays the real-time distribution of players with Game Style categories.',
    categoryType: 2
  }
};

export const alertChartConfig = {
  [AlertChart.multiAccount]: {
    name: 'Multi Account Alerts',
    tooltip: 'The chart displays the real time distribution of Multi Account alerts'
  },
  [AlertChart.linkedAccount]: {
    name: 'Linked Account Alerts',
    tooltip: 'The chart displays the real time distribution of Linked Account alerts'
  }
};

export const alertChartInitialData = [
  { TriggerId: 2, TriggerName: 'Payment system', ConfirmedAlertsCount: 0, CreatedAlertsCount: 0, RejectedAlertsCount: 0 },
  { TriggerId: 3, TriggerName: 'Fingerprint', ConfirmedAlertsCount: 0, CreatedAlertsCount: 0, RejectedAlertsCount: 0 },
  { TriggerId: 6, TriggerName: 'Birth Date & First Name & Last Name', ConfirmedAlertsCount: 0, CreatedAlertsCount: 0, RejectedAlertsCount: 0 },
  { TriggerId: 7, TriggerName: 'Last Name & Birth Date & Address', ConfirmedAlertsCount: 0, CreatedAlertsCount: 0, RejectedAlertsCount: 0 },
  { TriggerId: 8, TriggerName: 'Last Name & Birth Date & Email', ConfirmedAlertsCount: 0, CreatedAlertsCount: 0, RejectedAlertsCount: 0 },
  { TriggerId: 9, TriggerName: 'Birth Date & Address & Email', ConfirmedAlertsCount: 0, CreatedAlertsCount: 0, RejectedAlertsCount: 0 },
  { TriggerId: 10, TriggerName: 'Last Name & Email & Address', ConfirmedAlertsCount: 0, CreatedAlertsCount: 0, RejectedAlertsCount: 0 },
  { TriggerId: 11, TriggerName: 'Document Number', ConfirmedAlertsCount: 0, CreatedAlertsCount: 0, RejectedAlertsCount: 0 },
  { TriggerId: 12, TriggerName: 'Email', ConfirmedAlertsCount: 0, CreatedAlertsCount: 0, RejectedAlertsCount: 0 },
  { TriggerId: 13, TriggerName: 'Phone & Country', ConfirmedAlertsCount: 0, CreatedAlertsCount: 0, RejectedAlertsCount: 0 },
  { TriggerId: 20, TriggerName: 'Registration IP', ConfirmedAlertsCount: 0, CreatedAlertsCount: 0, RejectedAlertsCount: 0 }
];

export const AnalyticColorList = {
  1: 'rgba(63, 81, 181, 0.6)',
  2: 'rgba(255, 64, 129, 0.6)',
  3: 'rgba(28, 146, 28, 0.6)',
  4: 'rgba(255, 82, 82, 0.6)',
  5: 'rgba(255, 152, 0, 0.6)',
  6: 'rgba(123, 61, 185, 0.6)',
  7: 'rgba(144, 47, 12, 0.6)',
  8: 'rgb(139, 195, 74, 0.6)',
  9: 'rgba(60, 141, 188, 0.6)',
  11: 'rgba(63, 81, 181, 0.6)',
  12: 'rgba(255, 64, 129, 0.6)',
  25: 'rgba(28, 146, 28, 0.6)',
  26: 'rgba(255, 82, 82, 0.6)',
  27: 'rgba(255, 152, 0, 0.6)',
  34: 'rgba(123, 61, 185, 0.6)',
  35: 'rgba(144, 47, 12, 0.6)',
  101: 'rgba(63, 81, 181, 0.6)',
  102: 'rgba(255, 64, 129, 0.6)',
  103: 'rgba(28, 146, 28, 0.6)',
  104: 'rgba(255, 82, 82, 0.6)',
  105: 'rgba(255, 152, 0, 0.6)',
  106: 'rgba(144, 47, 12, 0.6)',
  107: 'rgba(116, 129, 200, 0.6)',
  108: 'rgba(247, 119, 110, 0.6)',
  109: 'rgba(0, 102, 153, 0.8)'
};

export const AlertColorList = {
  2: 'rgba(255, 64, 129, 0.6)',
  3: 'rgba(28, 146, 28, 0.6)',
  6: 'rgba(123, 61, 185, 0.6)',
  7: 'rgba(144, 47, 12, 0.6)',
  8: 'rgb(139, 195, 74, 0.6)',
  9: 'rgba(60, 141, 188, 0.6)',
  10: 'rgba(247, 119, 110, 0.6)',
  11: 'rgba(63, 81, 181, 0.6)',
  12: 'rgba(255, 64, 129, 0.6)',
  13: 'rgba(53,243,237,0.6)',
  20: 'rgba(226,135,67, 0.6)'
};

export const AnalyticLabelList = {
  1: 'Neutral',
  2: 'Positive',
  3: 'Negative',
  4: 'Very Negative',
  5: 'VIP Bronze',
  6: 'VIP Silver',
  7: 'VIP Gold',
  8: 'VIP Platinum',
  9: 'New User',
  11: 'Arbitrage Betting',
  12: 'SFM',
  25: 'Corridor',
  26: 'Late Betting',
  27: 'Strong Opinion',
  34: 'Bot Arbitrage',
  35: 'Bonus Hunter',
  101: 'Age',
  102: 'Women',
  103: 'Country',
  104: 'Currency',
  105: 'Dangerous IP',
  106: 'Custom',
  107: 'Fingerprint',
  108: 'Affiliate/BTag',
  109: 'City'
};

export const AlertNavigationParams = {
  trigger: {
    triggers: 1,
    autofetch: true
  },
  created: {
    triggers: 1,
    alertStatuses: [0],
    autofetch: true
  },
  confirmed: {
    triggers: 1,
    alertStatuses: [2],
    autofetch: true
  },
  rejected: {
    triggers: 1,
    alertStatuses: [3],
    autofetch: true
  }
};

export const AnalyticNavigationParams = {
  1: {
    categoryType: 1,
    categories: [1],
    autofetch: true
  },
  2: {
    categoryType: 1,
    categories: [2],
    autofetch: true
  },
  3: {
    categoryType: 1,
    categories: [3],
    autofetch: true
  },
  4: {
    categoryType: 1,
    categories: [4],
    autofetch: true
  },
  5: {
    categoryType: 1,
    categories: [5],
    autofetch: true
  },
  6: {
    categoryType: 1,
    categories: [6],
    autofetch: true
  },
  7: {
    categoryType: 1,
    categories: [7],
    autofetch: true
  },
  8: {
    categoryType: 1,
    categories: [8],
    autofetch: true
  },
  9: {
    categoryType: 1,
    categories: [9],
    autofetch: true
  },
  11: {
    categoryType: 2,
    categories: [11],
    autofetch: true
  },
  12: {
    categoryType: 2,
    categories: [12],
    autofetch: true
  },
  25: {
    categoryType: 2,
    categories: [25],
    autofetch: true
  },
  26: {
    categoryType: 2,
    categories: [26],
    autofetch: true
  },
  27: {
    categoryType: 2,
    categories: [27],
    autofetch: true
  },
  34: {
    categoryType: 2,
    categories: [34],
    autofetch: true
  },
  35: {
    categoryType: 2,
    categories: [35],
    autofetch: true
  },
  101: {
    categoryType: 3,
    categories: [101],
    autofetch: true
  },
  102: {
    categoryType: 3,
    categories: [102],
    autofetch: true
  },
  103: {
    categoryType: 3,
    categories: [103],
    autofetch: true
  },
  104: {
    categoryType: 3,
    categories: [104],
    autofetch: true
  },
  105: {
    categoryType: 3,
    categories: [105],
    autofetch: true
  },
  106: {
    categoryType: 3,
    categories: [106],
    autofetch: true
  },
  107: {
    categoryType: 3,
    categories: [107],
    autofetch: true
  },
  108: {
    categoryType: 3,
    categories: [108],
    autofetch: true
  },
  109: {
    categoryType: 3,
    categories: [109],
    autofetch: true
  }
};
