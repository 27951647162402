/**
 * @flow
 * */
import React, { useCallback, memo, useState, useEffect } from 'react';
import BusyLoader from '@geneui/components/BusyLoader';
import { elementZoomLevel } from 'zoom-level';
import Button from '@geneui/components/Button';
import Modal from '@geneui/components/Modal';

import { clearSettings, fetchAppSettings, updateAppSettings, updateSettings } from 'store/actions/appSettings/actions';
import { modalConfig, positions, busyLoader, buttonConfig } from 'config/settings/core-ui-strings';
import FailedDataLoadingError from 'views/components/FailedDataLoadingError';
import useContainerStatusHandler from 'hooks/useContainerStatusHandler';
import { setUserSettings } from 'store/actions/userSettings/actions';
import AppSettingsPopup from 'views/components/AppSettingsPopup';
import CacheService from 'core/services/storage/CacheService';
import { ViewModesSize } from 'config/settings/constants';
import useMessageHandler from 'hooks/useMessageHandler';
import messages from 'config/settings/messages';
import {
  appSettingsUpdateSelector,
  agpSettingsUpdateSelector,
  agpSettingsSelector,
  appSettingsSelector
} from 'store/selectors/appSettingsSelectors';
import config from 'config';

import './styles.scss';

type Props = {
    userId: number,
    setIsOpen: Function,
    isOpen: boolean,
}

/**
 *
 * @param {boolean} isOpen - if true, the popup will be shown
 * @param {number} userId - User Id
 * @param {Function} setIsOpen - change visible status
 * @returns {Node} -
 * @constructor
 */
const AppSettingsPopupContainer = ({ userId, isOpen, setIsOpen }: Props) => {
  const [selected, setSelected] = useState('');
  const [savedSelected, setSavedSelected] = useState('');
  const [timeZoneValue, setTimeZoneValue] = useState('');

  const {
    state: stateAGP
  } = useContainerStatusHandler({ selector: agpSettingsSelector });

  const {
    isSucceed: isSucceedAGPUpdate,
    isLoading: agpUpdateIsLoading
  } = useContainerStatusHandler({ selector: agpSettingsUpdateSelector, initiallyIsLoading: false });

  const setTimeZoneHandler = useCallback(() => {
    setTimeZoneValue(stateAGP.data.TimeZones.filter((item) => item.IsSelected)[0].Id);
  }, [setTimeZoneValue, stateAGP]);

  const {
    state,
    dispatch,
    isFailed,
    isSucceed
  } = useContainerStatusHandler({ selector: appSettingsSelector });

  const {
    isLoading: isUpdateLoading,
    isFailed: isUpdateFailed,
    isSucceed: isUpdateSucceed
  } = useContainerStatusHandler({ selector: appSettingsUpdateSelector, initiallyIsLoading: false });

  useMessageHandler({
    successMessage: messages.success.changed,
    errorMessage: state.error,
    isSucceed: isSucceedAGPUpdate,
    isFailed: isUpdateFailed
  });

  const fetchData = useCallback(() => {
    // $FlowFixMe
    dispatch(fetchAppSettings(userId));
  }, [dispatch, userId]);

  const updateViewMode = useCallback(() => {
    const hasChange = !(stateAGP.data.TimeZones.filter((item) => item.IsSelected)[0].Id === timeZoneValue);
    const isCurrentSelected = selected === '' || state.data.viewMode === selected;

    if (hasChange && !isCurrentSelected) {
      dispatch(updateSettings({ TimeZone: timeZoneValue }));
      dispatch(updateAppSettings({ userId, viewMode: selected || 'normal', language: '', isSizeChecked: state.data?.isSizeChecked }));
      dispatch(setUserSettings({ scheme: JSON.stringify({ viewMode: selected || 'normal', isSizeChecked: state.data?.isSizeChecked }), name: 'appSettings' }));
    } else if (hasChange && isCurrentSelected) {
      dispatch(updateSettings({ TimeZone: timeZoneValue }));
    } else {
      dispatch(updateAppSettings({ userId, viewMode: selected || 'normal', language: '', isSizeChecked: state.data?.isSizeChecked }));
      dispatch(setUserSettings({ scheme: JSON.stringify({ viewMode: selected || 'normal', isSizeChecked: state.data?.isSizeChecked }), name: 'appSettings' }));
    }
  }, [dispatch, selected, timeZoneValue, stateAGP, userId, state]);

  const changeStateHandler = useCallback(() => {
    if (state.data.viewMode && savedSelected !== state.data.viewMode) {
      setSavedSelected(state.data.viewMode);
    }
  }, [state, savedSelected, setSavedSelected]);

  const savedSelectedHandler = useCallback(() => {
    if (savedSelected) {
      const zoomedBlock = document.getElementsByTagName('body');
      // $FlowFixMe
      zoomedBlock[0].style.zoom = `${ViewModesSize[savedSelected]}%`;
      // $FlowFixMe
      zoomedBlock[0].className = `view-mode-${savedSelected}`;
      elementZoomLevel(zoomedBlock);
    }
  }, [savedSelected]);

  const isUpdateSucceedHandler = useCallback(() => {
    if (isUpdateSucceed && !agpUpdateIsLoading || isSucceedAGPUpdate && !isUpdateLoading) {
      dispatch(clearSettings());
      setIsOpen(false);
    }
  }, [dispatch, isUpdateSucceed, setIsOpen, isSucceedAGPUpdate, agpUpdateIsLoading, isUpdateLoading]);

  const isSucceedAGPUpdateHandler = useCallback(() => {
    if (isSucceedAGPUpdate) {
      const cacheGetSetting = JSON.parse(CacheService.getItem('callCaching') || '{}');
      // eslint-disable-next-line no-return-assign,no-param-reassign
      cacheGetSetting[`${config.agpApi}/en/setting/GetSetting`].body.Data.TimeZones.map((item) => ({ ...item.IsSelected = item.Id === timeZoneValue, ...item }));
      CacheService.setItem('callCaching', JSON.stringify(cacheGetSetting));
    }
  }, [isSucceedAGPUpdate, timeZoneValue]);

  const clearState = useCallback(() => {
    isOpen && dispatch(clearSettings());
  }, [isOpen, dispatch]);

  useEffect(isSucceedAGPUpdateHandler, [isSucceedAGPUpdate, timeZoneValue]);
  useEffect(isUpdateSucceedHandler, [dispatch, isUpdateSucceed, isUpdateSucceedHandler]);
  useEffect(savedSelectedHandler, [savedSelected]);
  useEffect(changeStateHandler, [state]);
  useEffect(clearState, [dispatch, isOpen]);
  useEffect(setTimeZoneHandler, []);
  useEffect(fetchData, [dispatch]);

  return isSucceed && (
    <Modal
      background={modalConfig.background.light}
      className="settings-popup-container"
      closeOnClickOutside={false}
      position={positions.center}
      visible={isOpen}
      cancelText="Cancel"
      closable={false}
      title="Settings"
      width={740}
      withPortal
      footer={(
        <>
          <Button
            onClick={() => setIsOpen(false)}
            appearance={buttonConfig.appearance.minimal}
          >Cancel
          </Button>
          <Button
            disabled={(selected === '' || state.data.viewMode === selected) && stateAGP.data.TimeZones.filter((item) => item.IsSelected)[0].Id === timeZoneValue}
            appearance={buttonConfig.appearance.default}
            color={buttonConfig.color.primary}
            onClick={updateViewMode}
          >Save
          </Button>
        </>
      )}
    >
      <BusyLoader isBusy={agpUpdateIsLoading} type={busyLoader.loaderType.spinner} spinnerSize={busyLoader.spinnerSize.big} />
      {
        isFailed
          ? <FailedDataLoadingError onRefresh={fetchData} />
          : <AppSettingsPopup selectedMode={selected || state.data.viewMode} setViewMode={setSelected} timeZoneValue={timeZoneValue} setTimeZoneValue={setTimeZoneValue} timeZones={stateAGP.data.TimeZones} />
      }
    </Modal>
  );
};

AppSettingsPopupContainer.displayName = 'AppSettingsPopupContainer';

export default memo<Props>(AppSettingsPopupContainer);
