/**
 * @flow
 * */
import FRMT_SETTINGS_TYPES from 'modules/FRMT/Settings/store/actions/settingsActions/types';
import { initialSectionWithSaveState } from 'store/reducers/initialStates';
import { SettingsState } from 'modules/FRMT/Settings/types';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import type { Action } from 'lib/core/flow';

const initialState = (): SettingsState => ({
  alertSettings: initialSectionWithSaveState()
});

const actionManager = {
  // alert settings
  [FRMT_SETTINGS_TYPES.FETCH_ALERT_SETTINGS]: (state: SettingsState): SettingsState => ({
    ...state,
    alertSettings: {
      ...state.alertSettings,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [FRMT_SETTINGS_TYPES.SET_FETCH_ALERT_SETTINGS_SUCCESS]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    alertSettings: {
      ...state.alertSettings,
      status: ACTION_STATUSES.SUCCEED,
      error: null,
      data: payload.data
    }
  }),
  [FRMT_SETTINGS_TYPES.SET_FETCH_ALERT_SETTINGS_FAILURE]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    alertSettings: {
      ...state.alertSettings,
      status: ACTION_STATUSES.FAILED,
      error: payload.error,
      data: null
    }
  }),
  [FRMT_SETTINGS_TYPES.CLEAR_ALERT_SETTINGS_STATE]: (state: SettingsState): SettingsState => ({
    ...state,
    alertSettings: initialState()
  }),

  // save alert settings
  [FRMT_SETTINGS_TYPES.SAVE_ALERT_SETTINGS]: (state: SettingsState): SettingsState => ({
    ...state,
    alertSettings: {
      ...state.alertSettings,
      save: {
        status: ACTION_STATUSES.PENDING,
        error: null
      }
    }
  }),
  [FRMT_SETTINGS_TYPES.SET_SAVE_ALERT_SETTINGS_SUCCESS]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    alertSettings: {
      ...state.alertSettings,
      save: {
        status: ACTION_STATUSES.SUCCEED,
        error: null,
        data: payload.data
      }
    }
  }),
  [FRMT_SETTINGS_TYPES.SET_SAVE_ALERT_SETTINGS_FAILURE]: (state: SettingsState, payload): SettingsState => ({
    ...state,
    alertSettings: {
      ...state.alertSettings,
      save: {
        status: ACTION_STATUSES.FAILED,
        error: payload.error,
        data: null
      }
    }
  }),
  [FRMT_SETTINGS_TYPES.CLEAR_SAVE_ALERT_SETTINGS_STATE]: (state: SettingsState): SettingsState => ({
    ...state,
    alertSettings: {
      ...state.alertSettings,
      save: initialState()
    }
  })

};

export default (state: SettingsState = initialState(), action: Action) => {
  if (!actionManager[action.type]) {
    return state;
  }

  return actionManager[action.type](state, action.payload);
};
