/**
 *  @author => @RMT_FRONT_CORE/CLI
 * */
import React from 'react';
import settings from 'config/constants';
import { Redirect } from 'react-router-dom';

const ChiSquareAlertsPage = React.lazy(() => import('./views/pages/ChiSquareAlerts'));
const DailyAlertsPage = React.lazy(() => import('./views/pages/DailyAlerts'));
const HourlyAlertsPage = React.lazy(() => import('./views/pages/HourlyAlerts'));
const StudioAlertsPage = React.lazy(() => import('./views/pages/StudioAlerts'));
const GameIntegrityAlertsPage = React.lazy(() => import('./views/pages/GameIntegrityAlerts'));

const subUrl = settings.PRODUCTS.LCRMT.path;
const modulePath = `${subUrl}/alerts`;
const modulePathOld = 'grmt/alerts';

type Route = {
  path: string,
  name: string,
  exact: boolean,
  page: Function,
  subRoutes?: Array<Route>,
}

const ROUTES: Array<Route> = [
  { path: `/${modulePath}/`, name: 'Alerts', exact: true, page: () => <Redirect to={`/${subUrl}/alerts/chi-square-alerts`} /> },
  { path: `/${modulePath}/chi-square-alerts`, name: 'Chi Square Alerts', exact: true, page: ChiSquareAlertsPage },
  { path: `/${modulePathOld}/`, name: 'Alerts', exact: true, page: () => <Redirect to="/grmt/alerts/chi-square-alerts" /> },
  { path: `/${modulePathOld}/chi-square-alerts`, name: 'Chi Square Alerts', exact: true, page: ChiSquareAlertsPage },

  { path: `/${modulePath}/daily-alerts`, name: 'Daily Alerts', exact: true, page: () => <Redirect to={`/${modulePath}/daily-alerts/opposite-betting-accounts`} /> },
  { path: `/${modulePath}/daily-alerts/:tab`, name: 'Tabs', exact: true, page: DailyAlertsPage },

  { path: `/${modulePath}/hourly-alerts`, name: 'Hourly Alerts', exact: true, page: () => <Redirect to={`/${modulePath}/hourly-alerts/margin-winners`} /> },
  { path: `/${modulePath}/hourly-alerts/:tab`, name: 'Hourly Alerts', exact: true, page: HourlyAlertsPage },

  { path: `/${modulePath}/studio-alerts`, name: 'Studio Alerts', exact: true, page: () => <Redirect to={`/${modulePath}/studio-alerts/cutting-card`} /> },
  { path: `/${modulePath}/studio-alerts/:tab`, name: 'Studio Alerts', exact: true, page: StudioAlertsPage },

  { path: `/${modulePath}/game-integrity-alerts`, name: 'Game Integrity Alerts', exact: true, page: () => <Redirect to={`/${modulePath}/game-integrity-alerts/gaming-margin`} /> },
  { path: `/${modulePath}/game-integrity-alerts/:tab`, name: 'Game Integrity Alerts', exact: true, page: GameIntegrityAlertsPage }
];

export default ROUTES;
