import settings from 'config/constants';
import ADMIN from './admin';
import LCRMT from './lcrmt';
import FRMT from './frmt';
import SRMT from './srmt';
import CRMT from './crmt';
import { generateAmlNav } from './aml';

const products = settings.PRODUCTS;

export default {
  [products.ADMIN.path]: () => ADMIN,
  [products.RMT.path]: () => SRMT,
  [products.CRMT.path]: () => CRMT,
  [products.LCRMT.path]: () => LCRMT,
  [products.CRMT.path]: () => CRMT,
  [products.FRMT.path]: () => FRMT,
  'grmt': () => LCRMT,
  [products.AML.path]: (config, license, hasOne) => generateAmlNav(config, license, hasOne)
};
