import createSymbol from 'lib/core/createSymbol';

const createDashboardSymbol = createSymbol('FRMT_SETTINGS');

export default {

  // alert settings
  FETCH_ALERT_SETTINGS: createDashboardSymbol('FETCH_ALERT_SETTINGS'),
  SET_FETCH_ALERT_SETTINGS_SUCCESS: createDashboardSymbol('SET_FETCH_ALERT_SETTINGS_SUCCESS'),
  SET_FETCH_ALERT_SETTINGS_FAILURE: createDashboardSymbol('SET_FETCH_ALERT_SETTINGS_FAILURE'),
  CLEAR_ALERT_SETTINGS_STATE: createDashboardSymbol('CLEAR_ALERT_SETTINGS_STATE'),

  // save alert settings
  SAVE_ALERT_SETTINGS: createDashboardSymbol('SAVE_ALERT_SETTINGS'),
  SET_SAVE_ALERT_SETTINGS_SUCCESS: createDashboardSymbol('SET_SAVE_ALERT_SETTINGS_SUCCESS'),
  SET_SAVE_ALERT_SETTINGS_FAILURE: createDashboardSymbol('SET_SAVE_ALERT_SETTINGS_FAILURE'),
  CLEAR_SAVE_ALERT_SETTINGS_STATE: createDashboardSymbol('CLEAR_SAVE_ALERT_SETTINGS_STATE'),

  // cancel call
  CANCEL_FRMT_SETTINGS_CALL: createDashboardSymbol('CANCEL_FRMT_SETTINGS_CALL')
};
