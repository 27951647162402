/**
 * @flow
 * */
import type { Action } from 'lib/core/flow';
import AML_TYPES from './types';

type State = {
  convertCurrencyType?: string,
  scoreRuleTypeId?: number,
  alertIds?: Array<number>,
  passTotalCount?:boolean,
  partnerIds?: Array<any>,
  activityFilter?: number,
  data?: Array<Object>,
  totalCount?: number,
  clientId?: number,
  dateFrom?: string,
  enabled?: boolean,
  playerId?: number,
  filters?: Object,
  license?: string,
  alertId?: number,
  dateTo?: string,
  ruleId?: number,
  tabKey?: string,
  count?: number,
  error?: string,
  typeId?: number,
  type?: string
}

// fetch boxes
export const fetchBoxes = () => ({
  type: AML_TYPES.FETCH_BOXES
});
export const setFetchBoxesSuccess = ({ data }: State) => ({
  type: AML_TYPES.SET_FETCH_BOXES_SUCCESS,
  payload: { data }
});
export const setFetchBoxesFailure = ({ error }: State) => ({
  type: AML_TYPES.SET_FETCH_BOXES_FAILURE,
  payload: { error }
});
export const clearBoxesState = () => ({
  type: AML_TYPES.CLEAR_BOXES
});

// fetch amlDashboard
export const fetchAmlDashboard = ({ partnerIds, dateFrom, dateTo, convertCurrencyType }: State):Action => ({
  type: AML_TYPES.FETCH_AML_DASHBOARD,
  payload: { partnerIds, dateFrom, dateTo, convertCurrencyType }
});
export const setFetchAmlDashboardSuccess = ({ data }: State):Action => ({
  type: AML_TYPES.SET_FETCH_AML_DASHBOARD_SUCCESS,
  payload: { data }
});
export const setFetchAmlDashboardFailure = ({ error }: State):Action => ({
  type: AML_TYPES.SET_FETCH_AML_DASHBOARD_FAILURE,
  payload: { error }
});
export const clearAmlDashboardState = ():Action => ({
  type: AML_TYPES.CLEAR_AML_DASHBOARD_STATE
});

// fetch transactions
export const fetchTransactions = ({ filters = {} }: State):Action => ({
  type: AML_TYPES.FETCH_TRANSACTIONS,
  payload: { filters }
});
export const setFetchTransactionsSuccess = ({ data, totalCount, passTotalCount }: State):Action => ({
  type: AML_TYPES.SET_FETCH_TRANSACTIONS_SUCCESS,
  payload: { data, totalCount, passTotalCount }
});
export const setFetchTransactionsFailure = ({ error }: State):Action => ({
  type: AML_TYPES.SET_FETCH_TRANSACTIONS_FAILURE,
  payload: { error }
});
export const clearTransactionsState = ():Action => ({
  type: AML_TYPES.CLEAR_TRANSACTIONS
});

// fetch configs
export const fetchConfigs = ({ filters, license }: State):Action => ({
  type: AML_TYPES.FETCH_CONFIGS,
  payload: { filters, license }
});
export const setFetchConfigsSuccess = ({ data, license }: State):Action => ({
  type: AML_TYPES.SET_FETCH_CONFIGS_SUCCESS,
  payload: { data, license }
});
export const setFetchConfigsFailure = ({ error }: State):Action => ({
  type: AML_TYPES.SET_FETCH_CONFIGS_FAILURE,
  payload: { error }
});
export const clearConfigsState = ():Action => ({
  type: AML_TYPES.CLEAR_CONFIGS
});

// fetch players
export const fetchAmlPlayers = ({ filters = {} }: State):Action => ({
  type: AML_TYPES.FETCH_AML_PLAYERS,
  payload: { filters }
});
export const setFetchAmlPlayersSuccess = ({ data, totalCount, passTotalCount }: State):Action => ({
  type: AML_TYPES.SET_FETCH_AML_PLAYERS_SUCCESS,
  payload: { data, totalCount, passTotalCount }
});
export const setFetchAmlPlayersFailure = ({ error }: State):Action => ({
  type: AML_TYPES.SET_FETCH_AML_PLAYERS_FAILURE,
  payload: { error }
});
export const clearAmlPlayersState = ():Action => ({
  type: AML_TYPES.CLEAR_AML_PLAYERS
});

// fetch player alerts
export const fetchAmlPlayerAlerts = ({ filters }: State):Action => ({
  type: AML_TYPES.FETCH_AML_PLAYER_ALERTS,
  payload: { filters }
});
export const setFetchAmlPlayerAlertSuccess = ({ data, totalCount }: State):Action => ({
  type: AML_TYPES.SET_FETCH_AML_PLAYER_ALERTS_SUCCESS,
  payload: { data, totalCount }
});
export const setFetchPLayerAlertsBulkReviewSuccess = ({ alertIds }: State):Action => ({
  type: AML_TYPES.SET_FETCH_PLAYER_ALERTS_BULK_REVIEW_SUCCESS,
  payload: { alertIds }
});
export const setFetchAmlPlayerAlertsFailure = ({ error }: State):Action => ({
  type: AML_TYPES.SET_FETCH_AML_PLAYER_ALERTS_FAILURE,
  payload: { error }
});
export const clearAmlPlayerAlertsState = ():Action => ({
  type: AML_TYPES.CLEAR_AML_PLAYER_ALERTS
});

// fetch player alerts bulk review
export const fetchAmlPlayerAlertsBulkReview = ({ filters }: State):Action => ({
  type: AML_TYPES.FETCH_AML_PLAYER_ALERTS_BULK_REVIEW,
  payload: { filters }
});
export const setFetchAmlPlayerAlertsBulkReviewSuccess = ():Action => ({
  type: AML_TYPES.SET_FETCH_AML_PLAYER_ALERTS_BULK_REVIEW_SUCCESS
});
export const setFetchAmlPlayerAlertsBulkReviewFailure = ({ error }: State):Action => ({
  type: AML_TYPES.SET_FETCH_AML_PLAYER_ALERTS_BULK_REVIEW_FAILURE,
  payload: { error }
});
export const clearAmlPlayerAlertsBulkReviewState = ():Action => ({
  type: AML_TYPES.CLEAR_AML_PLAYER_ALERTS_BULK_REVIEW
});

// fetch players multi accounts
export const fetchAmlPlayersMultiAccounts = ({ filters = {} }: State):Action => ({
  type: AML_TYPES.FETCH_AML_PLAYERS_MULTI_ACCOUNTS,
  payload: { filters }
});
export const setFetchAmlPlayersMultiAccountsSuccess = ({ data }: State):Action => ({
  type: AML_TYPES.SET_FETCH_AML_PLAYERS_MULTI_ACCOUNTS_SUCCESS,
  payload: { data }
});
export const setFetchAmlPlayersMultiAccountsFailure = ({ error }: State):Action => ({
  type: AML_TYPES.SET_FETCH_AML_PLAYERS_MULTI_ACCOUNTS_FAILURE,
  payload: { error }
});
export const clearAmlPlayersMultiAccountsState = ():Action => ({
  type: AML_TYPES.CLEAR_AML_PLAYERS_MULTI_ACCOUNTS
});

// fetch player score Rules
export const fetchPlayerScoreRules = ({ playerId }: State):Action => ({
  type: AML_TYPES.FETCH_PLAYER_SCORE_RULE,
  payload: { playerId }
});
export const setFetchPlayerScoreRulesSuccess = ({ data }: State):Action => ({
  type: AML_TYPES.SET_FETCH_PLAYER_SCORE_RULE_SUCCESS,
  payload: { data }
});
export const setFetchPlayerScoreRulesFailure = ({ error }: State):Action => ({
  type: AML_TYPES.SET_FETCH_PLAYER_SCORE_RULE_FAILURE,
  payload: { error }
});
export const clearPlayerScoreRulesState = ():Action => ({
  type: AML_TYPES.CLEAR_FETCH_PLAYER_SCORE_RULE
});

// fetch Change Score Count
export const fetchChangeScoreCount = ({ clientId, typeId, ruleId, scoreRuleTypeId }: State):Action => ({
  type: AML_TYPES.FETCH_CHANGE_SCORE_COUNT,
  payload: { clientId, typeId, ruleId, scoreRuleTypeId }
});
export const updateChangeScoreCount = ({ count, ruleId, enabled }: State):Action => ({
  type: AML_TYPES.UPDATE_CHANGE_SCORE_COUNT,
  payload: { count, ruleId, enabled }
});
export const setChangeScoreCountSuccess = ({ data, ruleId }: State):Action => ({
  type: AML_TYPES.SET_CHANGE_SCORE_COUNT_SUCCESS,
  payload: { data, ruleId }
});
export const setChangeScoreCountFailure = ({ error }: State):Action => ({
  type: AML_TYPES.SET_CHANGE_SCORE_COUNT_FAILURE,
  payload: { error }
});
export const clearChangeScoreCountState = ():Action => ({
  type: AML_TYPES.CLEAR_CHANGE_SCORE_COUNT
});

// fetch player score Rules Enabled/Disabled
export const fetchPlayerScoreRulesAction = ({ playerId, enabled, ruleId }: State):Action => ({
  type: AML_TYPES.FETCH_PLAYER_SCORE_RULE_ACTION,
  payload: { playerId, enabled, ruleId }
});
export const setFetchPlayerScoreRulesActionSuccess = ({ data }: State):Action => ({
  type: AML_TYPES.SET_FETCH_PLAYER_SCORE_RULE_ACTION_SUCCESS,
  payload: { data }
});
export const setFetchPlayerScoreRulesActionFailure = ({ error }: State):Action => ({
  type: AML_TYPES.SET_FETCH_PLAYER_SCORE_RULE_ACTION_FAILURE,
  payload: { error }
});
export const clearPlayerScoreRulesActionState = ():Action => ({
  type: AML_TYPES.CLEAR_FETCH_PLAYER_SCORE_RULE_ACTION
});

// fetch player block history report
export const fetchAmlPlayerHistoryReport = ({ filters = {} }: State):Action => ({
  type: AML_TYPES.FETCH_PLAYER_BLOCK_HISTORY_REPORT,
  payload: { filters }
});
export const setFetchAmlPlayerHistoryReportSuccess = ({ data, totalCount, passTotalCount }: State):Action => ({
  type: AML_TYPES.SET_FETCH_PLAYER_BLOCK_HISTORY_REPORT_SUCCESS,
  payload: { data, totalCount, passTotalCount }
});
export const setFetchAmlPlayerHistoryReportFailure = ({ error }: State):Action => ({
  type: AML_TYPES.SET_FETCH_PLAYER_BLOCK_HISTORY_REPORT_FAILURE,
  payload: { error }
});
export const clearAmlPlayerHistoryReportState = ():Action => ({
  type: AML_TYPES.CLEAR_PLAYER_BLOCK_HISTORY_REPORT_STATE
});

// fetch player score report
export const fetchAmlPlayerScoreReport = ({ filters = {} }: State):Action => ({
  type: AML_TYPES.FETCH_PLAYER_SCORE_REPORT,
  payload: { filters }
});
export const setFetchAmlPlayerScoreReportSuccess = ({ data, totalCount, passTotalCount }: State):Action => ({
  type: AML_TYPES.SET_FETCH_PLAYER_SCORE_REPORT_SUCCESS,
  payload: { data, totalCount, passTotalCount }
});
export const setFetchAmlPlayerScoreReportFailure = ({ error }: State):Action => ({
  type: AML_TYPES.SET_FETCH_PLAYER_SCORE_REPORT_FAILURE,
  payload: { error }
});
export const clearAmlPlayerScoreReportState = ():Action => ({
  type: AML_TYPES.CLEAR_PLAYER_SCORE_REPORT_STATE
});

// fetch aml rule score report
export const fetchAmlRuleScoreReport = ({ filters = {} }: State):Action => ({
  type: AML_TYPES.FETCH_AML_RULE_SCORE_REPORT,
  payload: { filters }
});
export const setFetchAmlRuleScoreReportSuccess = ({ data, totalCount, passTotalCount }: State):Action => ({
  type: AML_TYPES.SET_FETCH_AML_RULE_SCORE_REPORT_SUCCESS,
  payload: { data, totalCount, passTotalCount }
});
export const setFetchAmlRuleScoreReportFailure = ({ error }: State):Action => ({
  type: AML_TYPES.SET_FETCH_AML_RULE_SCORE_REPORT_FAILURE,
  payload: { error }
});
export const clearAmlRuleScoreReportState = ():Action => ({
  type: AML_TYPES.CLEAR_AML_RULE_SCORE_REPORT_STATE
});

// fetch aml alerts
export const fetchAmlAlerts = ({ filters = {}, tabKey }: State):Action => ({
  type: AML_TYPES.FETCH_AML_ALERTS,
  payload: { filters, tabKey }
});
export const setFetchAmlAlertsSuccess = ({ data, totalCount, tabKey, passTotalCount }: State):Action => ({
  type: AML_TYPES.SET_FETCH_AML_ALERTS_SUCCESS,
  payload: { data, totalCount, tabKey, passTotalCount }
});
export const setFetchAmlAlertsFailure = ({ error, tabKey }: State):Action => ({
  type: AML_TYPES.SET_FETCH_AML_ALERTS_FAILURE,
  payload: { error, tabKey }
});
export const clearAmlAlertsState = ({ tabKey }: State):Action => ({
  type: AML_TYPES.CLEAR_AML_ALERTS_STATE,
  payload: { tabKey }
});

// fetch multi account expanded data
export const fetchExpandedData = ({ alertId, tabKey }: State):Action => ({
  type: AML_TYPES.FETCH_AML_EXPANDED_DATA,
  payload: { alertId, tabKey }
});
export const fetchExpandedDataSuccess = ({ tabKey, data, alertId }: State):Action => ({
  type: AML_TYPES.FETCH_AML_EXPANDED_DATA_SUCCESS,
  payload: { tabKey, data, alertId }
});
export const fetchExpandedDataFailure = ({ error, tabKey, alertId }: State):Action => ({
  type: AML_TYPES.FETCH_AML_EXPANDED_DATA_FAILURE,
  payload: { error, tabKey, alertId }
});

// fetch multi account review history expanded data
export const fetchReviewHistoryExpandedData = ({ alertId, tabKey }: State):Action => ({
  type: AML_TYPES.FETCH_AML_ALERTS_REVIEW_HISTORY_EXPAND,
  payload: { alertId, tabKey }
});
export const fetchReviewHistoryExpandedDataSuccess = ({ tabKey, data, alertId }: State):Action => ({
  type: AML_TYPES.SET_FETCH_AML_ALERTS_REVIEW_HISTORY_EXPAND_SUCCESS,
  payload: { tabKey, data, alertId }
});
export const fetchReviewHistoryExpandedDataFailure = ({ error, tabKey, alertId }: State):Action => ({
  type: AML_TYPES.SET_FETCH_AML_ALERTS_REVIEW_HISTORY_EXPAND_FAILURE,
  payload: { error, tabKey, alertId }
});

// fetch aml alerts export
export const fetchAmlAlertsExport = ({ filters = {}, tabKey }: State):Action => ({
  type: AML_TYPES.FETCH_AML_ALERTS_EXPORT,
  payload: { filters, tabKey }
});
export const setFetchAmlAlertsExportSuccess = ({ data, tabKey }: State):Action => ({
  type: AML_TYPES.SET_FETCH_AML_ALERTS_EXPORT_SUCCESS,
  payload: { data, tabKey }
});
export const setFetchAmlAlertsExportFailure = ({ error, tabKey }: State):Action => ({
  type: AML_TYPES.SET_FETCH_AML_ALERTS_EXPORT_FAILURE,
  payload: { error, tabKey }
});
export const clearAmlAlertsExportState = ({ tabKey }: State):Action => ({
  type: AML_TYPES.CLEAR_AML_ALERTS_EXPORT_STATE,
  payload: { tabKey }
});

// fetch aml alerts review
export const fetchAmlAlertsReview = ({ filters = {}, tabKey }: State):Action => ({
  type: AML_TYPES.FETCH_AML_ALERTS_REVIEW,
  payload: { filters, tabKey }
});
export const setFetchAmlAlertsReviewSuccess = ({ data, tabKey }: State):Action => ({
  type: AML_TYPES.SET_FETCH_AML_ALERTS_REVIEW_SUCCESS,
  payload: { data, tabKey }
});
export const setFetchAmlAlertsReviewFailure = ({ error, tabKey }: State):Action => ({
  type: AML_TYPES.SET_FETCH_AML_ALERTS_REVIEW_FAILURE,
  payload: { error, tabKey }
});
export const clearAmlAlertsReviewState = ({ tabKey }: State):Action => ({
  type: AML_TYPES.CLEAR_AML_ALERTS_REVIEW_STATE,
  payload: { tabKey }
});

// fetch aml alerts review
export const fetchAmlAlertsReviewEdit = ({ filters = {}, tabKey }: State):Action => ({
  type: AML_TYPES.FETCH_AML_ALERTS_REVIEW_EDIT,
  payload: { filters, tabKey }
});
export const setFetchAmlAlertsReviewEditSuccess = ({ tabKey, data }: State):Action => ({
  type: AML_TYPES.SET_FETCH_AML_ALERTS_REVIEW_EDIT_SUCCESS,
  payload: { tabKey, data }
});
export const setFetchAmlAlertsReviewEditFailure = ({ error, tabKey }: State):Action => ({
  type: AML_TYPES.SET_FETCH_AML_ALERTS_REVIEW_EDIT_FAILURE,
  payload: { error, tabKey }
});
export const clearAmlAlertsReviewEditState = ({ tabKey }: State):Action => ({
  type: AML_TYPES.CLEAR_AML_ALERTS_REVIEW_EDIT_STATE,
  payload: { tabKey }
});

// fetch aml alerts review history
export const fetchAmlAlertsReviewHistory = ({ filters = {}, tabKey }: State):Action => ({
  type: AML_TYPES.FETCH_AML_ALERTS_REVIEW_HISTORY,
  payload: { filters, tabKey }
});
export const setFetchAmlAlertsReviewHistorySuccess = ({ tabKey, data }: State):Action => ({
  type: AML_TYPES.SET_FETCH_AML_ALERTS_REVIEW_HISTORY_SUCCESS,
  payload: { tabKey, data }
});
export const setFetchAmlAlertsReviewHistoryFailure = ({ error, tabKey }: State):Action => ({
  type: AML_TYPES.SET_FETCH_AML_ALERTS_REVIEW_HISTORY_FAILURE,
  payload: { error, tabKey }
});
export const clearAmlAlertsReviewHistoryState = ({ tabKey }: State):Action => ({
  type: AML_TYPES.CLEAR_AML_ALERTS_REVIEW_HISTORY_STATE,
  payload: { tabKey }
});

// fetch aml alerts bulk review
export const fetchAmlAlertsBulkReview = ({ filters = {}, tabKey }: State):Action => ({
  type: AML_TYPES.FETCH_AML_ALERTS_BULK_REVIEW,
  payload: { filters, tabKey }
});
export const setFetchAmlAlertsBulkReviewSuccess = ({ tabKey, data }: State):Action => ({
  type: AML_TYPES.SET_FETCH_AML_ALERTS_BULK_REVIEW_SUCCESS,
  payload: { tabKey, data }
});
export const setFetchAmlAlertsBulkReviewFailure = ({ error, tabKey }: State):Action => ({
  type: AML_TYPES.SET_FETCH_AML_ALERTS_BULK_REVIEW_FAILURE,
  payload: { error, tabKey }
});
export const clearAmlAlertsBulkReviewState = ({ tabKey }: State):Action => ({
  type: AML_TYPES.CLEAR_AML_ALERT_BULK_REVIEW_STATE,
  payload: { tabKey }
});

// Rule Score History
export const fetchAmlPlayerScoreHistory = ({ filters = {} }: State):Action => ({
  type: AML_TYPES.FETCH_PLAYER_SCORE_HISTORY,
  payload: { filters }
});
export const setFetchAmlPlayerScoreHistorySuccess = ({ data, totalCount }: State):Action => ({
  type: AML_TYPES.SET_FETCH_PLAYER_SCORE_HISTORY_SUCCESS,
  payload: { data, totalCount }
});
export const setFetchAmlPlayerScoreHistoryFailure = ({ error }: State):Action => ({
  type: AML_TYPES.SET_FETCH_PLAYER_SCORE_HISTORY_FAILURE,
  payload: { error }
});
export const clearAmlPlayerScoreHistoryState = ():Action => ({
  type: AML_TYPES.CLEAR_PLAYER_SCORE_HISTORY_STATE
});

// Risk Category History
export const fetchAmlRiskCategoryHistory = ({ filters = {}, tabKey }: State):Action => ({
  type: AML_TYPES.FETCH_AML_ALERT_RISK_CATEGORY,
  payload: { filters, tabKey }
});
export const setFetchAmlRiskCategoryHistorySuccess = ({ data, tabKey }: State):Action => ({
  type: AML_TYPES.SET_FETCH_AML_ALERT_RISK_CATEGORY_SUCCESS,
  payload: { data, tabKey }
});
export const setFetchAmlRiskCategoryHistoryFailure = ({ error, tabKey }: State):Action => ({
  type: AML_TYPES.SET_FETCH_AML_ALERT_RISK_CATEGORY_FAILURE,
  payload: { error, tabKey }
});
export const clearAmlRiskCategoryHistoryState = ({ tabKey }: State):Action => ({
  type: AML_TYPES.CLEAR_AML_ALERT_RISK_CATEGORY_STATE,
  payload: { tabKey }
});

// Transaction Activity
export const fetchTransactionActivity = ({ type, dateFrom, dateTo, playerId }: State): Action => ({
  type: AML_TYPES.FETCH_TRANSACTION_ACTIVITY_DATA,
  payload: { type, dateFrom, dateTo, playerId }
});
export const setTransactionActivitySuccess = ({ data }: State): Action => ({
  type: AML_TYPES.SET_FETCH_TRANSACTION_ACTIVITY_DATA_SUCCESS,
  payload: { data }
});
export const setFetchTransactionActivityFailure = ({ error }: State): Action => ({
  type: AML_TYPES.SET_FETCH_TRANSACTION_ACTIVITY_DATA_FAILURE,
  payload: { error }
});
export const clearTransactionActivityState = (): Action => ({
  type: AML_TYPES.CLEAR_TRANSACTION_ACTIVITY_DATA_STATE
});

// Betting Hourly Activity
export const fetchBettingHourlyActivity = ({ type, dateFrom, dateTo, playerId, activityFilter }: State): Action => ({
  type: AML_TYPES.FETCH_BETTING_HOURLY_ACTIVITY_DATA,
  payload: { type, dateFrom, dateTo, playerId, activityFilter }
});
export const setFetchBettingHourlyActivitySuccess = ({ data }: State): Action => ({
  type: AML_TYPES.SET_FETCH_BETTING_HOURLY_ACTIVITY_DATA_SUCCESS,
  payload: { data }
});
export const setFetchBettingHourlyActivityFailure = ({ error }: State): Action => ({
  type: AML_TYPES.SET_FETCH_BETTING_HOURLY_ACTIVITY_DATA_FAILURE,
  payload: { error }
});
export const clearBettingHourlyActivityState = (): Action => ({
  type: AML_TYPES.CLEAR_BETTING_HOURLY_ACTIVITY_DATA_STATE
});

// cancel aml call
export const cancelAmlCall = ({ name }:any): Action => ({
  type: AML_TYPES.CANCEL_AML_CALL,
  payload: { name }
});
