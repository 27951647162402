import React from 'react';
import Link from 'react-router-dom/es/Link';

import { permissions } from 'config/settings/permissions';
import settings from 'config/constants';

const products = settings.PRODUCTS;

const { path } = products.RMT;

export default [
  {
    id: `/${path}/dashboard`,
    title: <Link className="route_link" to={`/${path}/dashboard`}>Dashboard</Link>,
    icon: 'bc-icon-dashboard',
    permission: permissions.SRMTDashboardAndAnalyticData
  },
  {
    id: `/${path}/alert-monitor`,
    title: 'Alert Monitor',
    icon: 'bc-icon-alert-monitor-rmt-36',
    permission: [permissions.SRMTFraudAlerts, permissions.SRMTInfoAlerts],
    data: [
      {
        id: `/${path}/alert-monitor/fraud-monitor/linkedAccount`,
        title: <Link to={`/${path}/alert-monitor/fraud-monitor/linkedAccount`}>Fraud Monitor</Link>,
        permission: permissions.SRMTFraudAlerts
      },
      {
        id: `/${path}/alert-monitor/info-monitor/financial`,
        title: <Link to={`/${path}/alert-monitor/info-monitor/financial`}>Info Monitor</Link>,
        permission: permissions.SRMTInfoAlerts
      }
    ]
  },
  {
    id: `/${path}/players`,
    title: <Link className="route_link" to={`/${path}/players`}>Players</Link>,
    icon: 'bc-icon-players-rmt-36',
    permission: permissions.PlayersListAndIndividualPage
  },
  {
    id: `/${path}/player-groups`,
    title: <Link className="route_link" to={`/${path}/player-groups`}>Players Group</Link>,
    icon: 'bc-icon-players',
    permission: permissions.SRMTPlayersGroup
  },
  {
    id: `/${path}/reports`,
    title: 'Reports',
    icon: 'bc-icon-reports',
    permission: permissions.SRMTReports,
    data: [
      {
        id: `/${products.RMT.path}/reports/bets-reports`,
        title: <Link to={`/${products.RMT.path}/reports/bets-reports`}>Bets Report</Link>,
        permission: permissions.SRMTReports
      },
      {
        id: `/${products.RMT.path}/reports/user-player-reports`,
        title: <Link to={`/${products.RMT.path}/reports/user-player-reports`}>User-Player Report</Link>,
        permission: permissions.SRMTReports
      },
      {
        id: `/${products.RMT.path}/reports/alert-reports`,
        title: <Link to={`/${products.RMT.path}/reports/alert-reports`}>Alert Reports</Link>,
        permission: permissions.SRMTReports
      },
      {
        id: `/${products.RMT.path}/reports/player-block-status-reports`,
        title: <Link to={`/${products.RMT.path}/reports/player-block-status-reports`}>Player Block Status Reports</Link>,
        permission: permissions.SRMTReports
      },
      {
        id: `/${products.RMT.path}/reports/player-check-history-reports`,
        title: <Link to={`/${products.RMT.path}/reports/player-check-history-reports`}>Player Check History Reports</Link>,
        permission: permissions.SRMTReports
      }
    ]
  },
  {
    id: `/${path}/settings`,
    title: 'Settings',
    icon: 'bc-icon-settings',
    permission: permissions.SRMTSettings,
    data: [
      {
        id: `/${path}/settings/notification-settings`,
        title: <Link to={`/${path}/settings/notification-settings`}>Notification Settings</Link>,
        permission: permissions.SRMTSettings
      },
      {
        id: `/${path}/settings/category-settings/additional`,
        title: <Link to={`/${path}/settings/category-settings/additional`}>Category Settings</Link>,
        permission: permissions.SRMTSettings
      },
      {
        id: `/${path}/settings/settings-change-history`,
        title: <Link to={`/${path}/settings/settings-change-history`}>Settings Change History</Link>,
        permission: permissions.SRMTSettings
      },
      {
        id: `/${path}/settings/alert-monitor-settings`,
        title: <Link to={`/${path}/settings/alert-monitor-settings`}>Alert Monitor Settings</Link>,
        permission: permissions.SRMTSettings
      },
      {
        id: `/${path}/settings/trigger-settings`,
        title: <Link to={`/${path}/settings/trigger-settings`}>Trigger Settings</Link>,
        permission: permissions.SRMTSettings
      }
    ]
  }
];
