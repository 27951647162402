/**
 * @flow
 */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Dropdown from '@geneui/components/Dropdown';
import Switcher from '@geneui/components/Switcher';
import { BusyLoader, Icon } from '@geneui/components';
import Button from '@geneui/components/Button';
import Empty from '@geneui/components/Empty';

import DatePickerInputWithRangeWrapper from 'views/components/DatePickerInputWithRangeWrapper';
import GameStyleCategoryTag from 'views/components/Base/CategoryTags/GameStyleCategoryTag';
import { busyLoader, buttonConfig, inputConfig } from 'config/settings/core-ui-strings';
import useContainerStatusHandler from 'hooks/useContainerStatusHandler';
import {
  getNotificationFilterSelectors,
  notificationsSelectors
} from 'modules/Dashboard/Auth/store/selectors/notificationSelectors';
import { defaultDropdownConfigs } from 'config/settings/configs';
import CacheService from 'core/services/storage/CacheService';
import {
  fetchGetNotificationFilter,
  fetchMarkAsRead,
  fetchNotifications
} from 'modules/Dashboard/Auth/store/actions/notification';
import moment from 'moment';

import { AlertMonitorType, Product, ProductAlert, ProductsAlertType, ProductsURLAlertType } from './settings';
import './styles.scss';

const notificationStatusEnum = {
  created: 0,
  read: 1,
  underReview: 2,
  reviewed: 3

};

type Props = {
  data: Array<Object>,
  setData: Function,
  unread: boolean,
  setUnread: Function,
  alert: Array<Object>,
  setAlert: Function,
  alertType: Array<Object>,
  setAlertType: Function,
  product: Array<Object>,
  setProduct: Function,
  count: any,
  setCount: Function,
  dataLoading: boolean,
  filterRef: Object,
  userId: number,
  date: Object,
  setDate: Function
}

const NotificationPopup = ({
  data,
  setData,
  unread,
  setUnread,
  alert,
  setAlert,
  alertType,
  setAlertType,
  product,
  setProduct,
  count,
  setCount,
  dataLoading,
  filterRef,
  userId,
  date,
  setDate
}:Props) => {
  const [expand, setExpand] = useState(false);
  const [filterAlertType, setFilterAlertType] = useState([]);
  const [filterAlert, setFilterAlert] = useState([]);

  const boxRef = useRef(null);
  const makeDate = (date) => new Date(date).toLocaleDateString();

  const {
    state,
    isLoading,
    isFailed,
    isSucceed,
    dispatch
  } = useContainerStatusHandler({ selector: getNotificationFilterSelectors });

  const {
    state: dataState,
    isLoading: dataIsLoading,
    isSucceed: dataIsSucceed
  } = useContainerStatusHandler({ selector: notificationsSelectors });

  const getFilterData = useCallback(() => {
    dispatch(fetchGetNotificationFilter());
  }, [dispatch]);

  const clickRow = useCallback((item) => {
    if (item.Status === notificationStatusEnum.created) {
      const clickedIndex = data.findIndex(it => it === item);
      const updatedData = [...data];
      updatedData[clickedIndex] = { ...updatedData[clickedIndex], Status: 1 };
      setData(updatedData);
      setCount(count - 1);
      dispatch(fetchMarkAsRead({ data: { notificationId: item.Id } }));
    }
    let url = '';
    if (item.ProductId === 1) {
      url = `/${ProductAlert[item.ProductId]}/alert-monitor/${AlertMonitorType[item.AlertMonitorType]}/${ProductsURLAlertType[item.AlertTypeId]}?alertId=${item.AlertId}`;
    } else if (item.ProductId === 4) {
      url = `/${ProductAlert[item.ProductId]}/alerts/${AlertMonitorType[item.AlertMonitorType]}/${ProductsURLAlertType[item.AlertTypeId]}?alertId=${item.AlertId}`;
    } else if (item.ProductId === 2) {
      if (item.LicenseName === '') {
        CacheService.setItem('amlTurnkeyPartnerId', item.PartnerId);
        url = `/${ProductAlert[item.ProductId]}/turnkey/alert-monitor/${ProductsURLAlertType[item.AlertTypeId]}?alertId=${item.AlertId}`;
      } else {
        url = `/${ProductAlert[item.ProductId]}/${item.LicenseName.toLowerCase()}/alert-monitor/${ProductsURLAlertType[item.AlertTypeId]}?${item.AlertTypeId === 20 ? 'id' : 'alertId'}=${item.AlertId}`;
      }
    }
    window.open(url, '_blank');
  }, [dispatch, setCount, setData, data, count]);

  const onClickMarkAsRead = useCallback(() => {
    const newData = data.map(item => ({ ...item, Status: item.Status === notificationStatusEnum.underReview || item.Status === notificationStatusEnum.reviewed ? item.Status : 1 }));
    setData(newData);
    setCount(0);
    dispatch(fetchMarkAsRead({ data: { notificationId: null } }));
  }, [dispatch, setCount, data, setData]);

  const onSwitchHandler = useCallback(() => {
    setUnread(!unread);
  }, [setUnread, unread]);

  const changeProduct = useCallback((item) => {
    const tempArray = item.map(it => it.Id);
    const filterAlertTypeArray = state.data.AlertType.filter(el => tempArray.includes(el.ParentId)).map(elem => elem.Id);
    const localAlertTypesIs = alertType.filter(el => tempArray.includes(el.ParentId)).map(elem => elem.Id);
    setProduct(item.map(it => it.Id));
    setFilterAlertType(state.data.AlertType.filter(el => tempArray.includes(el.ParentId)));
    if (localAlertTypesIs.length) {
      setFilterAlert(state.data.Alert.filter(el => localAlertTypesIs.includes(el.ParentId)));
    } else {
      setFilterAlert(state.data.Alert.filter(el => filterAlertTypeArray.includes(el.ParentId)));
    }
    if (!item.length) {
      setAlertType([]);
      setAlert([]);
    } else {
      setAlertType(alertType.filter(el => tempArray.includes(el.ParentId)));
      setAlert(alert.filter(el => localAlertTypesIs.includes(el.ParentId)));
    }
  }, [setProduct, setFilterAlertType, state, setAlertType, setAlert, alertType, alert, setFilterAlert]);

  const changeAlertType = useCallback((item) => {
    const tempArray = item.map(it => it.Id);
    setAlertType(item);
    setFilterAlert(state.data.Alert.filter(el => tempArray.includes(el.ParentId)));
    if (!item.length) {
      setAlert([]);
    } else {
      setAlert(alert.filter(el => tempArray.includes(el.ParentId)));
    }
  }, [setAlertType, setFilterAlert, state, setAlert, alert]);

  const changeAlert = useCallback((item) => {
    setAlert(item);
  }, [setAlert]);

  const handleDatePickerInputChange = useCallback(([start, end]) => {
    if (start && end) {
      const startDate = moment(makeDate(start)).format('YYYY-MM-DD');
      const endDate = moment(makeDate(end)).format('YYYY-MM-DD');
      if (startDate !== date.startDate || endDate !== date.endDate) {
        setDate({
          startDate,
          endDate
        });
      }
    }
  }, [setDate, date]);

  const handleDatePickerInputClear = useCallback(() => {
    setDate({
      startDate: null,
      endDate: null
    });
  }, [setDate]);

  const dataList = useMemo(() => (
    <>
      {data.map((item, index) => (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions,react/no-array-index-key
        <div key={index} onClick={() => clickRow(item)} className="notification-box-item">
          <div className="notification-box-item-info">
            <div className="notification-box-item-info-left">
              <div className="notification-box-item-product">
                <GameStyleCategoryTag tagName={Product[item.ProductId]} />
              </div>
              {item.LicenseName && (
                <div className="notification-box-item-aml-box">
                  {item.LicenseName}
                </div>
              )}
              {item.ProductId === 2 && !item.LicenseName && (
                <div className="notification-box-item-aml-box">
                  Turnkey
                </div>
              )}
            </div>
            <div className="notification-box-item-info-right">
              <div className="notification-box-item-date">
                {item.Created}
              </div>
            </div>
          </div>
          <div className="notification-box-item-content">
            <p className="notification-box-item-text">
              New “{ProductsAlertType[item.AlertTypeId]}” has been triggered, you can check it with Alert ID: {item.AlertId}
            </p>
            {
              (item.Status === 3 || item.Status === 2) && (
                <Icon type="bc-icon-success-fill" />
              )
            }
            {
              item.Status === 0 && (
                <Icon type="bc-icon-status-circle" />
              )
            }
          </div>
        </div>
      ))}
    </>
  ), [data, clickRow]);

  const handleScroll = useCallback(() => {
    const box = boxRef.current;
    // $FlowFixMe
    if (box?.scrollTop + box?.clientHeight >= box?.scrollHeight - 50 && !dataLoading) {
      let tempAlert = [];
      if (alertType.length && !alert.length) {
        const alertTypesIdsList = alertType.map(item => item.Id);
        tempAlert = state.data.Alert.filter(el => alertTypesIdsList.includes(el.ParentId)).map(item => item.Id);
      } else if (alert.length) {
        tempAlert = alert.map(item => item.Id);
      }
      if (dataState.data && dataState.data.Notifications.length && dataState.data.Notifications.length >= 5 && !dataIsLoading) {
        dispatch(fetchNotifications({
          data: {
            ShowUnRead: unread,
            HasSetting: true,
            StartIndex: data.length,
            Count: 5,
            alertTypeIds: tempAlert,
            productIds: product,
            UserId: userId,
            createdDateFrom: date.startDate,
            createdDateTo: date.endDate
          }
        }));
      }
    }
  }, [data, dispatch, unread, product, userId, alertType, alert, dataIsLoading, dataState, date, dataLoading, state]);

  const checkFilterProduct = useCallback(() => {
    if (isSucceed) {
      if (!product.length) {
        setFilterAlertType([]);
        setFilterAlert([]);
      } else {
        setFilterAlertType(state.data.AlertType.filter(el => product.includes(el.ParentId)));
      }
      if (!alertType.length) {
        setFilterAlert([]);
      } else {
        setFilterAlert(state.data.Alert.filter(el => alertType.map(it => it.Id).includes(el.ParentId)));
      }
    }
  }, [state, isSucceed, setFilterAlertType, setFilterAlert, product, alertType]);

  useEffect(() => {
    const box = boxRef.current;
    // $FlowFixMe
    box.addEventListener('scroll', handleScroll);
    // $FlowFixMe
    return () => box.removeEventListener('scroll', handleScroll);
  }, [data, dataIsLoading, boxRef]);

  useEffect(getFilterData, [dispatch]);
  useEffect(checkFilterProduct, [isSucceed]);

  return (
    <div className={`notification-box-container ${expand ? 'expended' : ''}`} ref={filterRef}>

      <div className="notification-box-title-container">
        <div className="notification-box-title">
          Notifications
        </div>
        <div className="notification-box-expand">
          <Button
            icon={expand ? 'bc-icon-arrow-up' : 'bc-icon-arrow-down'}
            appearance={buttonConfig.appearance.minimal}
            color={buttonConfig.color.primary}
            onClick={() => setExpand(!expand)}
            disabled={isLoading || isFailed}
            className="btn-without-hover"
            withShadow={false}
          >
            {expand ? 'Condence' : 'Expand'}
          </Button>
        </div>
      </div>

      <div className="notification-box-actions">
        <Switcher
          className="notification-box-action-switcher"
          onChange={onSwitchHandler}
          label="Show unread"
          checked={unread}
        />
        <Button
          appearance={buttonConfig.appearance.minimal}
          className="btn-without-hover mark-all-read"
          color={buttonConfig.color.primary}
          onClick={onClickMarkAsRead}
          icon="bc-icon-check"
          withShadow={false}
          disabled={!count}
        >
          Mark All As Read
        </Button>
      </div>
      {expand && (
        <div className="notification-box-filter">
          <Dropdown
            {...isLoading || isFailed ? { icon: 'bc-icon-loader' } : {}}
            className="notifications-filter-dropdown-item"
            inputSize={inputConfig.size.default}
            disabled={isLoading || isFailed}
            size={inputConfig.size.default}
            {...defaultDropdownConfigs}
            data={state?.data?.Product}
            onChange={changeProduct}
            placeholder="Product"
            value={product}
            labelKey="Name"
            isMultiSelect
            valueKey="Id"
            isValid
          />
          <Dropdown
            {...isLoading || isFailed ? { icon: 'bc-icon-loader' } : {}}
            disabled={isLoading || isFailed || !product.length}
            className="notifications-filter-dropdown-item"
            inputSize={inputConfig.size.default}
            value={alertType.map(it => it.Id)}
            size={inputConfig.size.default}
            {...defaultDropdownConfigs}
            onChange={changeAlertType}
            placeholder="Alert Type"
            data={filterAlertType}
            labelKey="Name"
            isMultiSelect
            valueKey="Id"
            isValid
          />
          <Dropdown
            {...isLoading || isFailed ? { icon: 'bc-icon-loader' } : {}}
            disabled={isLoading || isFailed || !alertType.length}
            className="notifications-filter-dropdown-item"
            inputSize={inputConfig.size.default}
            size={inputConfig.size.default}
            value={alert.map(it => it.Id)}
            {...defaultDropdownConfigs}
            onChange={changeAlert}
            placeholder="Alert"
            data={filterAlert}
            labelKey="Name"
            isMultiSelect
            valueKey="Id"
            isValid
          />
          <DatePickerInputWithRangeWrapper
            value={[date.startDate || null, date.endDate || null]}
            labelAppearance={inputConfig.labelAppearance.swap}
            popoverAlign={inputConfig.itemsDirection.start}
            onChange={handleDatePickerInputChange}
            onClear={handleDatePickerInputClear}
            size={inputConfig.size.big}
            placeholder="Date"
            withRangeOptions
            label="Date"
          />
        </div>
      )}
      <BusyLoader isBusy={dataLoading} type={busyLoader.loaderType.spinner} spinnerSize={busyLoader.spinnerSize.big} />
      {/* $FlowFixMe */}
      <div className="notification-box" ref={boxRef}>
        {dataList}
        {dataIsSucceed && !data.length && <Empty className="empty-component" title="No Data to Display" />}
      </div>
    </div>
  );
};

NotificationPopup.displayName = 'NotificationPopup';

export default NotificationPopup;
