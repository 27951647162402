/**
 * @flow
 * */
import type { Action } from 'lib/core/flow';

import FRMT_SETTINGS_TYPES from './types';

// alert settings
export const alertSettings = () => ({
  type: FRMT_SETTINGS_TYPES.FETCH_ALERT_SETTINGS
});

export const alertSettingsSuccess = ({ data }: Object):Action => ({
  type: FRMT_SETTINGS_TYPES.SET_FETCH_ALERT_SETTINGS_SUCCESS,
  payload: { data }
});

export const alertSettingsFailure = ({ error }: Object):Action => ({
  type: FRMT_SETTINGS_TYPES.SET_FETCH_ALERT_SETTINGS_FAILURE,
  payload: { error }
});

export const clearAlertSettingsState = ():Action => ({
  type: FRMT_SETTINGS_TYPES.CLEAR_ALERT_SETTINGS_STATE
});

// save alert settings
export const saveAlertSettings = ({ params }:Object):Action => ({
  type: FRMT_SETTINGS_TYPES.SAVE_ALERT_SETTINGS,
  payload: { params }
});

export const saveAlertSettingsSuccess = ({ data }: Object):Action => ({
  type: FRMT_SETTINGS_TYPES.SET_SAVE_ALERT_SETTINGS_SUCCESS,
  payload: { data }
});

export const saveAlertSettingsFailure = ({ error }: Object):Action => ({
  type: FRMT_SETTINGS_TYPES.SET_SAVE_ALERT_SETTINGS_FAILURE,
  payload: { error }
});

export const clearSaveAlertSettingsState = ():Action => ({
  type: FRMT_SETTINGS_TYPES.CLEAR_SAVE_ALERT_SETTINGS_STATE
});

// cancel call
export const cancelSettingsSDKCalls = ({ name }: Object): Action => ({
  type: FRMT_SETTINGS_TYPES.CANCEL_FRMT_SETTINGS_CALL,
  payload: { name }
});
