import settingsController from 'modules/FRMT/Settings/core/controller/settingsController';
import settings from 'modules/FRMT/Settings/store/reducers';
import { combineReducers } from 'redux';

import SETTINGS_ROUTES from './Settings/routes';
import ALERT_ROUTES from './AlertMonitor/routes';

const getProjectState = (state) => state.FRMT;

const routes = [
  ...SETTINGS_ROUTES,
  ...ALERT_ROUTES
];

const controllers = [
  settingsController()
];

const reducers = combineReducers({
  ...settings
});

export {
  getProjectState,
  controllers,
  reducers,
  routes
};
