/**
 * @flow
 * */
import type { Action } from 'lib/core/flow';
import PLAYER_ACTIONS from './types';

// Player Info
export const fetchPlayerInfo = ({ id }: Object): Object => ({
  type: PLAYER_ACTIONS.FETCH_PLAYER_INFO_LCRMT,
  payload: { id }
});
export const setFetchPlayerInfoSuccess = ({ data }: Object): Object => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYER_INFO_LCRMT_SUCCESS,
  payload: { data }
});
export const setFetchPlayerInfoFailure = (error: string) => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYER_INFO_LCRMT_FAILURE,
  payload: { error }
});
export const updatePlayerInfo = (params: any) => ({
  type: PLAYER_ACTIONS.UPDATE_PLAYER_INFO_LCRMT,
  payload: { params }
});
export const clearPlayerInfo = () => ({
  type: PLAYER_ACTIONS.CLEAR_PLAYER_INFO_LCRMT_STATE
});

// Multi Accounts
export const fetchMultiAccounts = ({ playerId }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_LCRMT_MULTI_ACCOUNTS,
  payload: { playerId }
});
export const setFetchMultiAccountsSuccess = ({ data }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_LCRMT_MULTI_ACCOUNTS_SUCCESS,
  payload: { data }
});
export const setFetchMultiAccountsFailure = ({ error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_LCRMT_MULTI_ACCOUNTS_FAILURE,
  payload: { error }
});
export const clearMultiAccountsState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_LCRMT_MULTI_ACCOUNTS_STATE
});
