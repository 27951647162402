/**
 * @flow
 * */
import React from 'react';
import settings from 'config/constants';

// eslint-disable-next-line import/no-cycle
const CrmtPlayersGroupsLists = React.lazy(() => import('./views/pages/CrmtPlayersGroupsLists'));
const CrmtPlayersGroup = React.lazy(() => import('./views/pages/CrmtPlayersGroup'));

const modulePath = `/${settings.PRODUCTS.CRMT.path}/player-groups`;

type Route = {
  path: string,
  name: string,
  exact: boolean,
  page: Function,
  subRoutes?: Array<Route>,
}

const ROUTES: Array<Route> = [
  { path: `${modulePath}`, name: 'PlayerGroup', exact: true, page: CrmtPlayersGroupsLists },
  { path: `${modulePath}/:id`, name: 'PlayerGroupList', exact: true, page: CrmtPlayersGroup }
];

export default ROUTES;
