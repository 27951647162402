import React, { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useIndexedDB } from 'react-indexed-db';
import Link from 'react-router-dom/es/Link';

import { history } from 'config/history';
import settings from 'config/constants';
import moment from 'moment';

import Profile from '@geneui/components/Profile';
import Button from '@geneui/components/Button';
import { Time } from '@geneui/components';

import AmlLicenseNav from 'modules/Dashboard/Auth/views/containers/AmlLicenseNav';
import AppSettingsPopupContainer from 'views/containers/AppSettingsPopupContainer';
import HelpPopupContainer from 'views/containers/HelpPopupContainer';
import CacheService from 'core/services/storage/CacheService';
import { isAmlLicense } from 'modules/AML/utils/amlConfigs';

import { agpSettingsSelector } from 'store/selectors/appSettingsSelectors';
import useContainerStatusHandler from 'hooks/useContainerStatusHandler';
import userSettingsSDK from 'core/sdk/settings/UserSettingsSDK';
import { buttonConfig } from 'config/settings/core-ui-strings';
import { permissions } from 'config/settings/permissions';
import usePermission from 'hooks/usePermission';
import AGPSDK from 'core/sdk/AGPSDK/AGPSDK';
import Logger from 'lib/logger';

import howToConfig from '../HowToSidebar/config';
import Apps from '../Apps';
import Notification from '../Notification';

const products = settings.PRODUCTS;

function HeaderUser({ user }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isOpenNotification, setIsOpenNotification] = useState(false);
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const location = useLocation();
  const { hasSomeRead } = usePermission();
  const { clear } = useIndexedDB('pdf');

  const {
    state: stateAGP
  } = useContainerStatusHandler({ selector: agpSettingsSelector });

  const {
    isSRMT,
    isLcrmt,
    isAmlLicensePage
  } = useMemo(() => {
    const { pathname } = location;
    const license = isAmlLicense();
    return {
      isSRMT: pathname.includes(products.RMT.path),
      isLcrmt: pathname.includes(products.LCRMT.path),
      isAmlLicensePage: license?.name ? license : null
    };
  }, [location]);

  const goToAdmin = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const clickSettingsHandler = useCallback(() => {
    setIsSettingsOpen(true);
    setIsOpen(false);
  }, [setIsSettingsOpen, setIsOpen]);

  const clickNotificationHandler = useCallback(() => {
    setIsNotificationOpen(true);
    setIsOpenNotification(false);
    setIsOpen(false);
  }, [setIsNotificationOpen, setIsOpenNotification, setIsOpen]);

  const logOut = useCallback(async () => {
    const cache: Object = CacheService.getJson('hasSizeChanges');
    try {
      cache && await userSettingsSDK.updateUserSettings({ name: cache?.name, scheme: cache?.value });
    } catch (err) {
      Logger.error('There is a problem update data save.');
    }
    await AGPSDK.logOut();
    clear().then();
    CacheService.clearStorage();
    window.location.href = '/login';
  }, [clear]);

  const menuPropsData = useMemo(() => {
    const menuItems = [{
      title: <Link to="/admin">Admin Panel</Link>,
      onClick: goToAdmin,
      permission: permissions.AdminPanel
    },
    {
      title: 'Settings',
      onClick: clickSettingsHandler
    },
    {
      title: 'Notifications',
      onClick: clickNotificationHandler
    },
    {
      title: 'Log Out',
      onClick: logOut
    }];
    const result = menuItems.filter(item => {
      if (!item.permission) {
        return true;
      }
      return hasSomeRead(item.permission);
    });
    return result;
  },
  [goToAdmin, clickSettingsHandler, hasSomeRead, logOut, clickNotificationHandler]);

  const timeZone = useMemo(() => stateAGP?.data?.TimeZones?.filter((item) => item.IsSelected)[0].Id || 0, [stateAGP]);

  const startDate = useMemo(() => moment(moment().utcOffset(timeZone * 60).format('YYYY-MM-DD HH:mm:ss')).toDate(), [timeZone]);

  return (
    <>
      <div className="h-user">
        {isAmlLicensePage && (
          <>
            <div className="h-u-item">
              <AmlLicenseNav />
            </div>
            <div className="h-u-item">
              <div className="Divider" />
            </div>
          </>
        )}
        <div className="h-u-item header-time">
          <Time format="hh:mm:ss" startDate={startDate} />
        </div>
        <div className="h-u-item">
          <div className="Divider" />
        </div>
        <Apps />
        <div className="h-u-item">
          <Notification userId={user.Id} isOpenNotification={isOpenNotification} setIsOpenNotification={setIsOpenNotification} isNotificationOpen={isNotificationOpen} setIsNotificationOpen={setIsNotificationOpen} />
        </div>
        <div className="h-u-item">
          {isLcrmt && (
            <>
              <Button
                appearance={buttonConfig.appearance.minimal}
                style={{ color: 'white' }}
                onClick={setIsHelpOpen}
                icon="bc-icon-help"
              />
              <HelpPopupContainer isOpen={isHelpOpen} setIsOpen={setIsHelpOpen} />
            </>
          )}
          {isSRMT && (
            <Button
              appearance={buttonConfig.appearance.minimal}
              style={{ color: 'white' }}
              onClick={() => history.push(`/how-to?page=${howToConfig[0].name}`)}
              icon="bc-icon-help"
            />
          )}
        </div>
        <div className="h-u-item">
          <Profile
            avatarProps={{ color: 'white' }}
            username={`${user.FirstName}${'\xa0'.repeat(2)}ID:${user.Id || user.UserId}`}
            toggleHandler={(e, opened) => setIsOpen(!opened)}
            onOutsideClick={() => setIsOpen(false)}
            isOpen={isOpen}
            menuProps={{
              data: menuPropsData
            }}
          />
        </div>
      </div>
      <AppSettingsPopupContainer isOpen={isSettingsOpen} setIsOpen={setIsSettingsOpen} userId={user.Id || user.UserId} />
    </>
  );
}

export default HeaderUser;
