import { isEmptyObj } from './common';

export const deepClone = (obj: {}) => JSON.parse(JSON.stringify(obj));

export const setDefaultSize = ({ columns, sizeParams }) => {
  if (sizeParams && !isEmptyObj(sizeParams)) {
    return columns.map(item => {
      const newItem = deepClone(item);
      item.colRenderer && (newItem.colRenderer = item.colRenderer);
      item.render && (newItem.render = item.render);
      item.sortFn && (newItem.sortFn = item.sortFn);
      if (newItem.dataKey in sizeParams) {
        if (newItem.sizeParams) {
          newItem.sizeParams.defaultCustomWidth = sizeParams[newItem.dataKey];
        } else {
          newItem.sizeParams = {
            defaultCustomWidth: sizeParams[newItem.dataKey]
          };
        }
      }
      return newItem;
    });
  }
  return columns;
};

export const makeColumn = ({ columns, settingColumns, searchKey = 'text', sizeParams = null }) => {
  const savedSettings = settingColumns || [];
  const newRows = columns.length >= savedSettings.length
    ? columns.filter(i => !savedSettings.find(j => j.name === i[searchKey])) : [];

  const data = setDefaultSize({
    columns: [...savedSettings.filter(i => i.selected).map(i => columns.find(j => j[searchKey] === i.name)).filter(i => !!i), ...newRows],
    sizeParams
  });

  return data;
};
