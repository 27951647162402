import createSymbol, { createInternalSymbol } from 'lib/core/createSymbol';

const createPlayerInternalSymbol = createInternalSymbol('PLAYER');
const createPlayerSymbol = createSymbol('PLAYER');

export default {
  // Global Info
  FETCH_GLOBAL_INFO_LCRMT: createPlayerSymbol('FETCH_GLOBAL_INFO_LCRMT'),
  SET_FETCH_GLOBAL_INFO_LCRMT_SUCCESS: createPlayerSymbol('SET_FETCH_GLOBAL_INFO_LCRMT_SUCCESS'),
  SET_FETCH_GLOBAL_INFO_LCRMT_FAILURE: createPlayerSymbol('SET_FETCH_GLOBAL_INFO_LCRMT_FAILURE'),
  CLEAR_GLOBAL_INFO_LCRMT_STATE: createPlayerInternalSymbol('CLEAR_GLOBAL_INFO_LCRMT_STATE'),

  // Hourly Activity
  FETCH_HOURLY_ACTIVITY_LCRMT: createPlayerSymbol('FETCH_HOURLY_ACTIVITY_LCRMT'),
  SET_FETCH_HOURLY_ACTIVITY_LCRMT_SUCCESS: createPlayerSymbol('SET_FETCH_HOURLY_ACTIVITY_LCRMT_SUCCESS'),
  SET_FETCH_HOURLY_ACTIVITY_LCRMT_FAILURE: createPlayerSymbol('SET_FETCH_HOURLY_ACTIVITY_LCRMT_FAILURE'),
  CLEAR_HOURLY_ACTIVITY_LCRMT_STATE: createPlayerInternalSymbol('CLEAR_HOURLY_ACTIVITY_LCRMT_STATE'),

  // Global KPIs
  FETCH_GLOBAL_KPIS_LCRMT: createPlayerSymbol('FETCH_GLOBAL_KPIS_LCRMT'),
  SET_FETCH_GLOBAL_KPIS_LCRMT_SUCCESS: createPlayerSymbol('SET_FETCH_GLOBAL_KPIS_LCRMT_SUCCESS'),
  SET_FETCH_GLOBAL_KPIS_LCRMT_FAILURE: createPlayerSymbol('SET_FETCH_GLOBAL_KPIS_LCRMT_FAILURE'),
  CLEAR_GLOBAL_KPIS_LCRMT_STATE: createPlayerInternalSymbol('CLEAR_GLOBAL_KPIS_LCRMT_STATE'),

  // Player Info
  FETCH_PLAYER_INFO_LCRMT: createPlayerSymbol('FETCH_PLAYER_INFO_LCRMT'),
  SET_FETCH_PLAYER_INFO_LCRMT_SUCCESS: createPlayerSymbol('SET_FETCH_PLAYER_INFO_LCRMT_SUCCESS'),
  SET_FETCH_PLAYER_INFO_LCRMT_FAILURE: createPlayerSymbol('SET_FETCH_PLAYER_INFO_LCRMT_FAILURE'),
  UPDATE_PLAYER_INFO_LCRMT: createPlayerSymbol('UPDATE_PLAYER_INFO_LCRMT'),
  CLEAR_PLAYER_INFO_LCRMT_STATE: createPlayerInternalSymbol('CLEAR_PLAYER_INFO_LCRMT_STATE'),

  // PlayerList
  FETCH_LCRMT_PLAYERS: createPlayerSymbol('FETCH_LCRMT_PLAYERS'),
  SET_FETCH_LCRMT_PLAYERS_SUCCESS: createPlayerSymbol('SET_FETCH_LCRMT_PLAYERS_SUCCESS'),
  SET_FETCH_LCRMT_PLAYERS_APPEND_SUCCESS: createPlayerSymbol('SET_FETCH_LCRMT_PLAYERS_APPEND_SUCCESS'),
  SET_FETCH_LCRMT_PLAYERS_FAILURE: createPlayerSymbol('SET_FETCH_LCRMT_PLAYERS_FAILURE'),
  CLEAR_LCRMT_PLAYERS_STATE: createPlayerInternalSymbol('CLEAR_LCRMT_PLAYERS_STATE'),

  // live Games Restrictions
  FETCH_LIVE_GAMES_RESTRICTIONS: createPlayerSymbol('FETCH_LIVE_GAMES_RESTRICTIONS'),
  SET_FETCH_LIVE_GAMES_RESTRICTIONS_SUCCESS: createPlayerSymbol('SET_FETCH_LIVE_GAMES_RESTRICTIONS_SUCCESS'),
  SET_FETCH_LIVE_GAMES_RESTRICTIONS_FAILURE: createPlayerSymbol('SET_FETCH_LIVE_GAMES_RESTRICTIONS_FAILURE'),
  CLEAR_LIVE_GAMES_RESTRICTIONS_STATE: createPlayerInternalSymbol('CLEAR_LIVE_GAMES_RESTRICTIONS_STATE'),

  // add live Games Restrictions
  FETCH_ADD_LIVE_GAMES_RESTRICTIONS: createPlayerSymbol('FETCH_ADD_LIVE_GAMES_RESTRICTIONS'),
  SET_FETCH_ADD_LIVE_GAMES_RESTRICTIONS_SUCCESS: createPlayerSymbol('SET_FETCH_ADD_LIVE_GAMES_RESTRICTIONS_SUCCESS'),
  SET_FETCH_ADD_LIVE_GAMES_RESTRICTIONS_FAILURE: createPlayerSymbol('SET_FETCH_ADD_LIVE_GAMES_RESTRICTIONS_FAILURE'),
  CLEAR_ADD_LIVE_GAMES_RESTRICTIONS_STATE: createPlayerInternalSymbol('CLEAR_ADD_LIVE_GAMES_RESTRICTIONS_STATE'),

  // delete live Games Restrictions
  FETCH_DELETE_LIVE_GAMES_RESTRICTIONS: createPlayerSymbol('FETCH_DELETE_LIVE_GAMES_RESTRICTIONS'),
  SET_FETCH_DELETE_LIVE_GAMES_RESTRICTIONS_SUCCESS: createPlayerSymbol('SET_FETCH_DELETE_LIVE_GAMES_RESTRICTIONS_SUCCESS'),
  SET_FETCH_DELETE_LIVE_GAMES_RESTRICTIONS_FAILURE: createPlayerSymbol('SET_FETCH_DELETE_LIVE_GAMES_RESTRICTIONS_FAILURE'),
  CLEAR_DELETE_LIVE_GAMES_RESTRICTIONS_STATE: createPlayerInternalSymbol('CLEAR_DELETE_LIVE_GAMES_RESTRICTIONS_STATE'),

  // Tables By Category
  FETCH_TABLES_BY_CATEGORY: createPlayerSymbol('FETCH_TABLES_BY_CATEGORY'),
  SET_FETCH_TABLES_BY_CATEGORY_SUCCESS: createPlayerSymbol('SET_FETCH_TABLES_BY_CATEGORY_SUCCESS'),
  SET_FETCH_TABLES_BY_CATEGORY_FAILURE: createPlayerSymbol('SET_FETCH_TABLES_BY_CATEGORY_FAILURE'),
  CLEAR_TABLES_BY_CATEGORY_STATE: createPlayerInternalSymbol('CLEAR_TABLES_BY_CATEGORY_STATE'),

  // Game Categories
  FETCH_GAME_CATEGORIES: createPlayerSymbol('FETCH_GAME_CATEGORIES'),
  SET_FETCH_GAME_CATEGORIES_SUCCESS: createPlayerSymbol('SET_FETCH_GAME_CATEGORIES_SUCCESS'),
  SET_FETCH_GAME_CATEGORIES_FAILURE: createPlayerSymbol('SET_FETCH_GAME_CATEGORIES_FAILURE'),
  CLEAR_GAME_CATEGORIES_STATE: createPlayerInternalSymbol('CLEAR_GAME_CATEGORIES_STATE'),

  // Activity Session
  FETCH_ACTIVITY_SESSION: createPlayerSymbol('FETCH_ACTIVITY_SESSION'),
  SET_FETCH_ACTIVITY_SESSION_SUCCESS: createPlayerSymbol('SET_FETCH_ACTIVITY_SESSION_SUCCESS'),
  SET_FETCH_ACTIVITY_SESSION_FAILURE: createPlayerSymbol('SET_FETCH_ACTIVITY_SESSION_FAILURE'),
  CLEAR_ACTIVITY_SESSION_STATE: createPlayerInternalSymbol('CLEAR_ACTIVITY_SESSION_STATE'),

  // Activity Session View
  FETCH_ACTIVITY_SESSION_VIEW: createPlayerSymbol('FETCH_ACTIVITY_SESSION_VIEW'),
  SET_FETCH_ACTIVITY_SESSION_VIEW_SUCCESS: createPlayerSymbol('SET_FETCH_ACTIVITY_SESSION_VIEW_SUCCESS'),
  SET_FETCH_ACTIVITY_SESSION_VIEW_FAILURE: createPlayerSymbol('SET_FETCH_ACTIVITY_SESSION_VIEW_FAILURE'),
  CLEAR_ACTIVITY_SESSION_VIEW_STATE: createPlayerInternalSymbol('CLEAR_ACTIVITY_SESSION_VIEW_STATE'),

  // Activity Session View Expand
  FETCH_ACTIVITY_SESSION_VIEW_EXPAND: createPlayerSymbol('FETCH_ACTIVITY_SESSION_VIEW_EXPAND'),
  SET_FETCH_ACTIVITY_SESSION_VIEW_EXPAND_SUCCESS: createPlayerSymbol('SET_FETCH_ACTIVITY_SESSION_VIEW_EXPAND_SUCCESS'),
  SET_FETCH_ACTIVITY_SESSION_VIEW_EXPAND_FAILURE: createPlayerSymbol('SET_FETCH_ACTIVITY_SESSION_VIEW_EXPAND_FAILURE'),

  // Activity Session Statistics
  FETCH_ACTIVITY_SESSION_STATISTICS: createPlayerSymbol('FETCH_ACTIVITY_SESSION_STATISTICS'),
  SET_FETCH_ACTIVITY_SESSION_STATISTICS_SUCCESS: createPlayerSymbol('SET_FETCH_ACTIVITY_SESSION_STATISTICS_SUCCESS'),
  SET_FETCH_ACTIVITY_SESSION_STATISTICS_FAILURE: createPlayerSymbol('SET_FETCH_ACTIVITY_SESSION_STATISTICS_FAILURE'),
  CLEAR_ACTIVITY_SESSION_STATISTICS_STATE: createPlayerInternalSymbol('CLEAR_ACTIVITY_SESSION_STATISTICS_STATE'),

  // Alerts
  FETCH_LCRMT_PLAYERS_ALERTS: createPlayerSymbol('FETCH_LCRMT_PLAYERS_ALERTS'),
  SET_FETCH_LCRMT_PLAYERS_ALERTS_SUCCESS: createPlayerSymbol('SET_FETCH_LCRMT_PLAYERS_ALERTS_SUCCESS'),
  SET_FETCH_LCRMT_PLAYERS_ALERTS_FAILURE: createPlayerSymbol('SET_FETCH_LCRMT_PLAYERS_ALERTS_FAILURE'),
  SET_LCRMT_PLAYERS_ALERTS_DATA_CHANGE_AFTER_REVIEW: createPlayerSymbol('SET_LCRMT_PLAYERS_ALERTS_DATA_CHANGE_AFTER_REVIEW'),
  CLEAR_LCRMT_PLAYERS_ALERTS_STATE: createPlayerInternalSymbol('CLEAR_LCRMT_PLAYERS_ALERTS_STATE'),

  // gaming kpi
  FETCH_LCRMT_PLAYER_FINANCIAL_GAMING_KPI: createPlayerSymbol('FETCH_LCRMT_PLAYER_FINANCIAL_GAMING_KPI'),
  SET_FETCH_LCRMT_PLAYER_FINANCIAL_GAMING_KPI_SUCCESS: createPlayerSymbol('SET_FETCH_LCRMT_PLAYER_FINANCIAL_GAMING_KPI_SUCCESS'),
  SET_FETCH_LCRMT_PLAYER_FINANCIAL_GAMING_KPI_FAILURE: createPlayerSymbol('SET_FETCH_LCRMT_PLAYER_FINANCIAL_GAMING_KPI_FAILURE'),
  CLEAR_LCRMT_PLAYER_FINANCIAL_GAMING_KPI_STATE: createPlayerInternalSymbol('CLEAR_LCRMT_PLAYER_FINANCIAL_GAMING_KPI_STATE'),

  // gaming table
  FETCH_LCRMT_PLAYER_FINANCIAL_GAMING_TABLE: createPlayerSymbol('FETCH_LCRMT_PLAYER_FINANCIAL_GAMING_TABLE'),
  SET_FETCH_LCRMT_PLAYER_FINANCIAL_GAMING_TABLE_SUCCESS: createPlayerSymbol('SET_FETCH_LCRMT_PLAYER_FINANCIAL_GAMING_TABLE_SUCCESS'),
  SET_FETCH_LCRMT_PLAYER_FINANCIAL_GAMING_TABLE_FAILURE: createPlayerSymbol('SET_FETCH_LCRMT_PLAYER_FINANCIAL_GAMING_TABLE_FAILURE'),
  CLEAR_LCRMT_PLAYER_FINANCIAL_GAMING_TABLE_STATE: createPlayerInternalSymbol('CLEAR_LCRMT_PLAYER_FINANCIAL_GAMING_TABLE_STATE'),

  // activity
  FETCH_LCRMT_PLAYER_FINANCIAL_ACTIVITY: createPlayerSymbol('FETCH_LCRMT_PLAYER_FINANCIAL_ACTIVITY'),
  SET_FETCH_LCRMT_PLAYER_FINANCIAL_ACTIVITY_SUCCESS: createPlayerSymbol('SET_FETCH_LCRMT_PLAYER_FINANCIAL_ACTIVITY_SUCCESS'),
  SET_FETCH_LCRMT_PLAYER_FINANCIAL_ACTIVITY_FAILURE: createPlayerSymbol('SET_FETCH_LCRMT_PLAYER_FINANCIAL_ACTIVITY_FAILURE'),
  CLEAR_LCRMT_PLAYER_FINANCIAL_ACTIVITY_STATE: createPlayerInternalSymbol('CLEAR_LCRMT_PLAYER_FINANCIAL_ACTIVITY_STATE'),

  // transactions kpi
  FETCH_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_KPI: createPlayerSymbol('FETCH_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_KPI'),
  SET_FETCH_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_KPI_SUCCESS: createPlayerSymbol('SET_FETCH_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_KPI_SUCCESS'),
  SET_FETCH_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_KPI_FAILURE: createPlayerSymbol('SET_FETCH_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_KPI_FAILURE'),
  CLEAR_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_KPI_STATE: createPlayerInternalSymbol('CLEAR_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_KPI_STATE'),

  // transactions table
  FETCH_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_TABLE: createPlayerSymbol('FETCH_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_TABLE'),
  SET_FETCH_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_TABLE_SUCCESS: createPlayerSymbol('SET_FETCH_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_TABLE_SUCCESS'),
  SET_FETCH_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_TABLE_FAILURE: createPlayerSymbol('SET_FETCH_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_TABLE_FAILURE'),
  CLEAR_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_TABLE_STATE: createPlayerInternalSymbol('CLEAR_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_TABLE_STATE'),

  // Multi accounts
  FETCH_LCRMT_MULTI_ACCOUNTS: createPlayerSymbol('FETCH_LCRMT_MULTI_ACCOUNTS'),
  SET_FETCH_LCRMT_MULTI_ACCOUNTS_SUCCESS: createPlayerSymbol('SET_FETCH_LCRMT_MULTI_ACCOUNTS_SUCCESS'),
  SET_FETCH_LCRMT_MULTI_ACCOUNTS_FAILURE: createPlayerSymbol('SET_FETCH_LCRMT_MULTI_ACCOUNTS_FAILURE'),
  CLEAR_LCRMT_MULTI_ACCOUNTS_STATE: createPlayerInternalSymbol('CLEAR_LCRMT_MULTI_ACCOUNTS_STATE'),

  // Player SDK cancel
  CANCEL_LCRMT_PLAYER_SDK_CALL: createPlayerSymbol('CANCEL_LCRMT_PLAYER_SDK_CALL')
};
