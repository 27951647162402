export const GameStyleCategoryTags = {
  ArbitrageBetting: {
    color: '#7481c9',
    name: 'Arbitrage Betting'
  },
  Arbitrage_Betting: {
    color: '#7481c9',
    name: 'Arbitrage Betting'
  },
  'Arbitrage Betting': {
    color: '#7481c9',
    name: 'Arbitrage Betting'
  },
  'Arbitrage Betting 1': {
    color: '#7481c9',
    name: 'Arbitrage Betting 1'
  },
  'Arbitrage Betting 2': {
    color: '#7481c9',
    name: 'Arbitrage Betting 2'
  },
  'Arbitrage Betting 3': {
    color: '#7481c9',
    name: 'Arbitrage Betting 3'
  },
  'Arbitrage Bot': {
    color: '#cd92d7',
    name: 'Arbitrage Bot'
  },
  'Bot_Arb': {
    color: '#cd92d7',
    name: 'Arbitrage Bot'
  },
  'SFM': {
    color: '#f48eb0',
    name: 'SFM'
  },
  'SFM 1': {
    color: '#f48eb0',
    name: 'SFM 1'
  },
  'SFM 2': {
    color: '#f48eb0',
    name: 'SFM 2'
  },
  'SFM 3': {
    color: '#f48eb0',
    name: 'SFM 3'
  },
  Corridor: {
    color: '#7dc681',
    name: 'Corridor'
  },
  'Corridor 1': {
    color: '#7dc681',
    name: 'Corridor 1'
  },
  'Corridor 2': {
    color: '#7dc681',
    name: 'Corridor 2'
  },
  'Corridor 3': {
    color: '#7dc681',
    name: 'Corridor 3'
  },
  LateBetting: {
    color: '#f9a19a',
    name: 'Late Betting'
  },
  Late_Betting: {
    color: '#f9a19a',
    name: 'Late Betting'
  },
  'Late Betting 1': {
    color: '#f9a19a',
    name: 'Late Betting 1'
  },
  'Late Betting 2': {
    color: '#f9a19a',
    name: 'Late Betting 2'
  },
  'Late Betting 3': {
    color: '#f9a19a',
    name: 'Late Betting 3'
  },
  StrongOpinion: {
    color: '#ffca5f',
    name: 'Strong Opinion'
  },
  Strong_Opinion: {
    color: '#ffca5f',
    name: 'Strong Opinion'
  },
  'Strong Opinion': {
    color: '#ffca5f',
    name: 'Strong Opinion'
  },
  'Strong Opinion 1': {
    color: '#ffca5f',
    name: 'Strong Opinion 1'
  },
  'Strong Opinion 2': {
    color: '#ffca5f',
    name: 'Strong Opinion 2'
  },
  'Strong Opinion 3': {
    color: '#ffca5f',
    name: 'Strong Opinion 3'
  },
  BotArb: {
    color: '#cd92d7',
    name: 'Bot Arbitrage'
  },
  'Bot Arb': {
    color: '#cd92d7',
    name: 'Bot Arbitrage'
  },
  'Bot Arb 1': {
    color: '#cd92d7',
    name: 'Bot Arbitrage 1'
  },
  'Bot Arb 2': {
    color: '#cd92d7',
    name: 'Bot Arbitrage 2'
  },
  'Bot Arb 3': {
    color: '#cd92d7',
    name: 'Bot Arbitrage 3'
  },
  'Bot Arbitrage': {
    color: '#cd92d7',
    name: 'Bot Arbitrage'
  },
  'Bot Arbitrage 1': {
    color: '#cd92d7',
    name: 'Bot Arbitrage 1'
  },
  'Bot Arbitrage 2': {
    color: '#cd92d7',
    name: 'Bot Arbitrage 2'
  },
  'Bot Arbitrage 3': {
    color: '#cd92d7',
    name: 'Bot Arbitrage 3'
  },
  Bonus_Hunter: {
    color: '#b26c72',
    name: 'Bonus Hunter'
  },
  'Bonus Hunter': {
    color: '#b26c72',
    name: 'Bonus Hunter'
  },
  'Bonus Hunter 1': {
    color: '#b26c72',
    name: 'Bonus Hunter 1'
  },
  'Bonus Hunter 2': {
    color: '#b26c72',
    name: 'Bonus Hunter 2'
  },
  'Bonus Hunter 3': {
    color: '#b26c72',
    name: 'Bonus Hunter 3'
  }
};

export const AssessmentsTags = {
  'Not a threat': {
    color: '#1EA66C',
    name: 'Not a Threat'
  },
  'Under investigation': {
    color: '#F48A3A',
    name: 'Under investigation'
  },
  'OPP betting': {
    color: '#FFCA5F',
    name: 'OPP betting'
  },
  'SOPP betting': {
    color: '#FFE24E',
    name: 'SOPP betting'
  },
  'Advantage play': {
    color: '#EF5D8F',
    name: 'Advantage play'
  },
  'Bonus abuse': {
    color: '#5FB4F6',
    name: 'Bonus abuse'
  },
  'Tournament abuse': {
    color: '#B763C6',
    name: 'Tournament abuse'
  },
  'Wonging': {
    color: '#CD1D59',
    name: 'Wonging'
  },
  'Card Counting': {
    color: '#7481C9',
    name: 'Card Counting'
  },
  'Advanced Card Counting': {
    color: '#ABBB36',
    name: 'Advanced Card Counting'
  },
  'System issues': {
    color: '#737373',
    name: 'System issues'
  },
  'Fraudulent activity': {
    color: '#E64E48',
    name: 'Fraudulent activity'
  },
  'Suspicious tips': {
    color: '#B10F78',
    name: 'Suspicious tips'
  }
};

export const AdditionalCategoryTags = {
  Female: {
    color: '#ef5d8f',
    name: 'Female'
  },
  Age: {
    color: '#47b4a9',
    name: 'Age'
  },
  Country: {
    color: '#5fb4f6',
    name: 'Country'
  },
  City: {
    color: '#006699',
    name: 'City'
  },
  Custom: {
    color: '#b763c6',
    name: 'Custom'
  },
  Currency: {
    color: '#76a145',
    name: 'Currency'
  },
  'Dangerous IP': {
    color: '#cd1d59',
    name: 'Dangerous IPs'
  },
  'Predefined IP': {
    color: '#5c3796',
    name: 'Predefined IPs'
  },
  'Women': {
    color: '#5c3796',
    name: 'Women'
  },
  Additional: {
    color: '#b763c6',
    name: 'Additional'
  },
  'High Risk': {
    color: '#7124ec',
    name: 'High Risk'
  },
  HighRisk: {
    color: '#7124ec',
    name: 'High Risk'
  },
  'Not Playing': {
    color: '#662177',
    name: 'Not Playing'
  },
  NotPlaying: {
    color: '#662177',
    name: 'Not Playing'
  },
  'No Bonus User': {
    color: '#cf64ea',
    name: 'No Bonus User'
  },
  NoBonusUser: {
    color: '#cf64ea',
    name: 'No Bonus User'
  },
  Casino: {
    color: '#cf64ea',
    name: 'Casino'
  },
  Agent: {
    color: '#cf64ea',
    name: 'Agent'
  },
  'Test User': {
    color: '#d91984',
    name: 'Test User'
  },
  TestUser: {
    color: '#d91984',
    name: 'Test User'
  },
  '1/2SFM': {
    color: '#730341',
    name: '1/2SFM'
  },
  Half_SFM: {
    color: '#730341',
    name: '1/2SFM'
  },
  Strong: {
    color: '#730341',
    name: 'Strong'
  },
  'Before VIP': {
    color: '#8d1a26',
    name: 'Before VIP'
  },
  BeforeVIP: {
    color: '#8d1a26',
    name: 'Before VIP'
  },
  Review: {
    color: '#8d1a26',
    name: 'Review'
  },
  'Value Bet': {
    color: '#e5838e',
    name: 'Value Bet'
  },
  ValueBet: {
    color: '#e5838e',
    name: 'Value Bet'
  },
  VeryNegative: {
    color: '#e5838e',
    name: 'Very Negative'
  },
  'BetShop Agent': {
    color: '#50191f',
    name: 'BetShop Agent'
  },
  BetShopAgent: {
    color: '#50191f',
    name: 'BetShop Agent'
  },
  Fingerprint: {
    name: 'Fingerprint',
    color: '#7481c8'
  },
  107: {
    name: 'Fingerprint',
    color: '#7481c8'
  },
  AffilateIdBTag: {
    color: '#f7776e',
    name: 'Affiliate/BTag'
  },
  'Affiliate/BTag': {
    color: '#f7776e',
    name: 'Affiliate/BTag'
  },
  'AffiliateId/BTag': {
    color: '#f7776e',
    name: 'Affiliate/BTag'
  },
  108: {
    color: '#f7776e',
    name: 'Affiliate/BTag'
  }
};

export const FinancialCategoryTags = {
  'New User': {
    color: '#47cfe0',
    name: 'New User'
  },
  New_User: {
    color: '#47cfe0',
    name: 'New User'
  },
  'Neutral': {
    color: '#f48a3a',
    name: 'Neutral'
  },
  'Positive': {
    color: '#46904a',
    name: 'Positive'
  },
  'Negative': {
    color: '#e03f33',
    name: 'Negative'
  },
  'Very Negative': {
    color: '#bb1414',
    name: 'Very Negative'
  },
  Very_Negative: {
    color: '#bb1414',
    name: 'Very Negative'
  },
  'VIP': {
    color: '#822691',
    name: 'VIP'
  },
  'VIP Bronze': {
    color: '#822691',
    name: 'VIP Bronze'
  },
  'VIP Silver': {
    color: '#0091ce',
    name: 'VIP Silver'
  },
  'VIP Gold': {
    color: '#207ede',
    name: 'VIP Gold'
  },
  'VIP Platinum': {
    color: '#007e74',
    name: 'VIP Platinum'
  },
  'Late Betting': {
    color: '#f9a19a',
    name: 'Late Betting'
  },
  'Age': {
    color: '#47b4a9',
    name: 'Age'
  },
  'Arbitrage Betting': {
    color: '#7481c9',
    name: 'Arbitrage Betting'
  },
  'SFM': {
    color: '#f48eb0',
    name: 'SFM'
  },
  'Corridor': {
    color: '#7dc681',
    name: 'Corridor'
  },
  'Strong Opinion': {
    color: '#ffca5f',
    name: 'Strong Opinion'
  },
  'Bot Arb': {
    color: '#cd92d7',
    name: 'Bot Arbitrage'
  },
  'Bonus Hunter': {
    color: '#b26c72',
    name: 'Bonus Hunter'
  },
  'Country': {
    color: '#5fb4f6',
    name: 'Country'
  },
  'Currency': {
    color: '#76a145',
    name: 'Currency'
  },
  'Dangerous IP': {
    color: '#cd1d59',
    name: 'Dangerous IP'
  },
  'Custom': {
    color: '#b763c6',
    name: 'Custom'
  },
  'Women': {
    color: '#ef5d8f',
    name: 'Women'
  }
};

export const AmlAdditionalRiskCategoryTags = {
  None: {
    color: '',
    name: 'None'
  },
  Low: {
    color: '#abbb36',
    name: 'Low'
  },
  Medium: {
    color: '#f48a3a',
    name: 'Medium'
  },
  High: {
    color: '#f7776e',
    name: 'High'
  },
  VeryHigh: {
    color: '#e03f33',
    name: 'Very High'
  },
  'Very High': {
    color: '#e03f33',
    name: 'Very High'
  }
};

export const PlayerStatuses = {
  1: {
    color: '#ef5d8f',
    name: 'Temporary'
  },
  Temporary: {
    color: '#ef5d8f',
    name: 'Temporary'
  },
  2: {
    color: '#5fb4f6',
    name: 'Permanent'
  },
  Permanent: {
    color: '#5fb4f6',
    name: 'Permanent'
  },
  3: {
    color: '#f7776e',
    name: 'Deactivated'
  },
  Deactivated: {
    color: '#f7776e',
    name: 'Deactivated'
  },
  4: {
    color: '#abbb36',
    name: 'Closed'
  },
  Closed: {
    color: '#abbb36',
    name: 'Closed'
  },
  5: {
    color: '#cd1d59',
    name: 'Forbidden'
  },
  Forbidden: {
    color: '#cd1d59',
    name: 'Forbidden'
  },
  6: {
    color: '#e03f33',
    name: 'Blocked'
  },
  Blocked: {
    color: '#e03f33',
    name: 'Blocked'
  },
  7: {
    color: '#20b2aa',
    name: 'Open'
  },
  Open: {
    color: '#20b2aa',
    name: 'Open'
  },
  8: {
    color: '#8fbc8b',
    name: 'Suspended'
  },
  Suspended: {
    color: '#8fbc8b',
    name: 'Suspended'
  },
  9: {
    color: '#4682b4',
    name: 'Dormant'
  },
  Dormant: {
    color: '#4682b4',
    name: 'Dormant'
  }
};

export const Type = {
  1: {
    color: '#822691',
    name: 'Single'
  },
  2: {
    color: '#46904A',
    name: 'Express'
  },
  'Accepted': {
    color: '#46904A',
    name: 'Accepted'
  },
  'Cash Out': {
    color: '#822691',
    name: 'Cash Out'
  },
  'Lost': {
    color: '#cd1d59',
    name: 'Lost'
  },
  'New': {
    color: '#4682b4',
    name: 'New'
  },
  'Rejected': {
    color: '#b26c72',
    name: 'Rejected'
  },
  'Returned': {
    color: '#20b2aa',
    name: 'Returned'
  },
  'Won': {
    color: '#7481c8',
    name: 'Won'
  },
  'Pending Detail': {
    color: '#213cd5',
    name: 'Pending Detail'
  },
  'Waiting Partner': {
    color: '#58c212',
    name: 'Waiting Partner'
  },
  'Rollbacked': {
    color: '#5411a6',
    name: 'Rollbacked'
  }
};

export const OppositeType = {
  'Opposite': {
    color: '#F1A100',
    name: 'Opposite'
  },
  'Self Opposite': {
    color: '#F48A3A',
    name: 'Self Opposite'
  },
  Mixed: {
    color: '#FA8072',
    name: 'Mixed'
  }
};

export const ProductsType = {
  'SRMT': {
    color: '#FFA45F',
    name: 'SRMT'
  },
  'LCRMT': {
    color: '#77A31B',
    name: 'LCRMT'
  },
  'AML & RG': {
    color: '#3BBCBC',
    name: 'AML & RG'
  }
};
