const expandReducerChildren = (allData, payload, data, status, error, dataKey = 'AlertId') => allData.map(e => {
  if (payload.alertId === e[dataKey]) {
    return {
      ...e,
      expand: {
        status,
        error,
        data
      }
    };
  }
  return { ...e };
});

export default expandReducerChildren;
