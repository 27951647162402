/**
 * @flow
 * */
import { initialSectionState } from 'store/reducers/initialStates';
import type { PlayerState } from 'modules/LCRMT/Players/types';
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import PLAYER_ACTION_TYPES from '../actions/player/types';

const initialState = {
  playerInfo: initialSectionState()
};

const actionManager = {
  // Player Info
  [PLAYER_ACTION_TYPES.FETCH_PLAYER_INFO_LCRMT]: (state: PlayerState) => ({
    ...state,
    playerInfo: {
      data: null,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYER_INFO_LCRMT_SUCCESS]: (state: PlayerState, payload) => ({
    ...state,
    playerInfo: {
      data: payload.data,
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYER_INFO_LCRMT_FAILURE]: (state: PlayerState, payload) => ({
    ...state,
    playerInfo: {
      data: null,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.UPDATE_PLAYER_INFO_LCRMT]: (state: PlayerState, payload) => ({
    ...state,
    playerInfo: {
      data: {
        ...state.playerInfo.data,
        ...payload.params
      },
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_PLAYER_INFO_LCRMT_STATE]: (state: PlayerState) => ({
    ...state,
    playerInfo: initialSectionState()
  })
};

export default {
  actionManager,
  initialState
};
