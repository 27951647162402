/**
 * @flow
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { BusyLoader } from '@geneui/components';
import Button from '@geneui/components/Button';
import Modal from '@geneui/components/Modal';

import {
  clearGetAddUpdateNotification,
  fetchGetAddUpdateNotification,
  fetchGetNotification
} from 'modules/Dashboard/Auth/store/actions/notification';
import { busyLoader, buttonConfig, modalConfig, positions } from 'config/settings/core-ui-strings';
import FailedDataLoadingError from 'views/components/FailedDataLoadingError';
import useContainerStatusHandler from 'hooks/useContainerStatusHandler';
import {
  getAddUpdateNotificationSelectors,
  getNotificationSelectors
} from 'modules/Dashboard/Auth/store/selectors/notificationSelectors';

import NotificationSettingsItem from './NotificationSettingsItem';
import './styles.scss';

type Props = {
  setIsNotificationOpen: Function,
  isNotificationOpen: boolean
}

const NotificationSettingsModal = ({ isNotificationOpen, setIsNotificationOpen }:Props) => {
  const [data, setData] = useState([]);
  const [settingsData, setSettingsData] = useState({});

  const {
    state,
    isSucceed,
    isLoading,
    isFailed,
    dispatch
  } = useContainerStatusHandler({ selector: getNotificationSelectors });

  const {
    isSucceed: addUpdateIsSucceed,
    isLoading: addUpdateIsLoading
  } = useContainerStatusHandler({ selector: getAddUpdateNotificationSelectors, initiallyIsLoading: false });

  const makeData = useCallback(() => {
    const tempData = [];
    if (isSucceed && state.data.length) {
      state.data.forEach((item) => {
        const { ProductType, AlertType, Alert } = item;
        // Find the index of the ProductType
        const productTypeIndex = tempData.findIndex(data => data && data.ProductType === ProductType);
        if (productTypeIndex === -1) {
          // ProductType not found, create a new entry
          tempData.push({
            ProductType,
            Alerts: [{
              AlertType,
              AlertTypeId: item.AlertTypeId,
              IsSelected: item.IsSelected
            }],
            Alert: [{
              Alert,
              AlertId: item.AlertId,
              AlertTypeId: item.AlertTypeId,
              IsSelected: item.IsSelected
            }]
          });
        } else {
          // ProductType found, check for AlertType
          const alertTypeIndex = tempData[productTypeIndex].Alerts.findIndex(alert => alert.AlertType === AlertType);
          if (alertTypeIndex === -1) {
            // AlertType not found, create a new entry
            tempData[productTypeIndex].Alerts.push({
              AlertType,
              AlertTypeId: item.AlertTypeId,
              IsSelected: item.IsSelected,
              Alert: [{
                Alert,
                AlertId: item.AlertId,
                AlertTypeId: item.AlertTypeId,
                IsSelected: item.IsSelected
              }]
            });
            tempData[productTypeIndex].Alert.push({
              Alert,
              AlertId: item.AlertId,
              AlertTypeId: item.AlertTypeId,
              IsSelected: item.IsSelected
            });
          } else {
            tempData[productTypeIndex].Alert.push({
              Alert,
              AlertId: item.AlertId,
              AlertTypeId: item.AlertTypeId,
              IsSelected: item.IsSelected
            });
            if (item.IsSelected) {
              tempData[productTypeIndex].Alerts.forEach(it => {
                if (it.AlertTypeId === item.AlertTypeId) {
                  // eslint-disable-next-line no-param-reassign
                  it.IsSelected = true;
                }
              });
            }
          }
        }
      });
    }
    setData(tempData);
  }, [isSucceed, state, setData]);

  const fetchData = useCallback(() => {
    dispatch(fetchGetNotification());
  }, [dispatch]);

  const makeAlertData = useCallback(item => {
    const localAlerts = item.alerts.map((el) => el.AlertTypeId);
    const localAlert = item.alertIds.filter(it => localAlerts.includes(it.AlertTypeId)).map((el) => el.AlertId);
    setSettingsData((ev) => ({ ...ev, [item.ProductType]: { alertIds: localAlert, alerts: localAlerts } }));
  }, [setSettingsData]);

  const line = useMemo(() => (
    <>
      {data.map(item => (
        <NotificationSettingsItem data={item} makeAlertData={makeAlertData} />
      ))}
    </>
  ), [data, makeAlertData]);

  const saveSettings = useCallback(() => {
    let arrayData = [];
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in settingsData) {
      arrayData = arrayData.concat(settingsData[key].alertIds);
    }
    dispatch(fetchGetAddUpdateNotification({ data: arrayData }));
  }, [settingsData, dispatch]);

  const checkSaveActive = useMemo(() => {
    let arrayAlerts = [];
    let arrayAlert = [];
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in settingsData) {
      arrayAlerts = arrayAlerts.concat(settingsData[key].alerts);
      arrayAlert = arrayAlert.concat(settingsData[key].alertIds);
    }
    if (!arrayAlerts.length && !arrayAlert.length) {
      return true;
    }
    if (arrayAlerts.length && arrayAlert.length) {
      return true;
    }
    return false;
  }, [settingsData]);

  const closePopup = useCallback(() => {
    if (addUpdateIsSucceed) {
      setIsNotificationOpen(false);
    }
  }, [setIsNotificationOpen, addUpdateIsSucceed]);

  const clearState = useCallback(() => () => {
    dispatch(clearGetAddUpdateNotification());
  }, [dispatch]);

  useEffect(fetchData, [dispatch]);
  useEffect(makeData, [isSucceed]);
  useEffect(closePopup, [addUpdateIsSucceed]);
  useEffect(clearState, [dispatch]);

  return (
    <Modal
      background={modalConfig.background.light}
      className="notifications-popup-container"
      closeOnClickOutside={false}
      position={positions.center}
      visible={isNotificationOpen}
      title="Notifications"
      cancelText="Cancel"
      closable={false}
      width={580}
      withPortal
      footer={(
        <>
          <Button
            onClick={() => setIsNotificationOpen(false)}
            appearance={buttonConfig.appearance.minimal}
          >Cancel
          </Button>
          <Button
            disabled={isLoading || isFailed || !checkSaveActive}
            appearance={buttonConfig.appearance.default}
            color={buttonConfig.color.primary}
            onClick={saveSettings}
          >Save
          </Button>
        </>
      )}
    >
      <BusyLoader isBusy={isLoading || addUpdateIsLoading} type={busyLoader.loaderType.spinner} spinnerSize={busyLoader.spinnerSize.small} />
      {
        isFailed
          ? <FailedDataLoadingError onRefresh={fetchData} />
          : (
            <div className="notifications-popup-body">
              {line}
            </div>
          )
      }
    </Modal>
  );
};

NotificationSettingsModal.displayName = 'NotificationSettingsModal';

export default NotificationSettingsModal;
