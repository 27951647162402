/**
 * @flow
 * */
import { AlertSettingsData, SaveAlertData, SaveAlertParas } from 'modules/FRMT/Settings/types';
import NetworkService from 'core/services/network/NetworkService';
import BaseSDK from 'core/sdk/BaseSDK';
import config from 'config';

/**
 * @class SettingSDK
 */
class SettingSDK extends BaseSDK {
    _rmtApiService: NetworkService;

    constructor() {
      super();
      this._rmtApiService = new NetworkService(config.rmtApi);
    }

    /**
     * fetchAlertSettings -
     * @returns {Promise<WithData<AlertSettingsData>>} -
     */
    async fetchAlertSettings(): Promise<{data: Array<AlertSettingsData>}> {
      const { body } = await this._rmtApiService.makeAPIGetRequest(
        'FRMT/GetAmlSettings',
        { query_params: { }, abortController: this.setAbortController('getSettings') }
      );

      return { data: body.Data };
    }

    /**
     * saveAlertSettings -
     * @returns {Array<SaveAlertData>} -
     */
    async saveAlertSettings({ params }: SaveAlertParas):Promise<SaveAlertData> {
      const { body } = await this._rmtApiService.makeAPIPostRequest(
        'FRMT/SaveAmlSettings',
        { body: params }
      );
      return { data: body.Data };
    }

    /**
     * cancelSettingsSDKCalls
     * @param {Object} params -
     * @returns {Object} -
     */
    cancelSettingsSDKCalls(params: { name: string | Array<string> }): void {
      const { name } = params;
      this.abortCall(name);
    }
}

export default new SettingSDK();
