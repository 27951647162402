/* eslint-disable  no-mixed-operators */
import { deepClone } from 'lib/helpers/makeData';
import {
  AmlRuleScoreRuleCategoryInitialData,
  RgRuleScoreRuleCategoryInitialData,
  ScoreRuleCategoryInitialData,
  RuleConfigInitialData
} from './initialData';
import { GamingControlEnum } from '../types';

const RuleConfigs = [
  {
    name: 'Low',
    id: 1
  },
  {
    name: 'Medium',
    id: 2
  },
  {
    name: 'High',
    id: 3
  },
  {
    name: 'Very High',
    id: 4
  }
];

export const modifyAmlData = (data) => {
  const newData = deepClone(data);
  if (newData) {
    if (newData.NotificationSettingsTemplates) {
      const notificationSettingsTemplates = [];
      newData.NotificationSettingsTemplates.forEach(item => {
        notificationSettingsTemplates.unshift({
          Id: item.Id,
          Name: item.Name,
          Frequency: item.Frequency,
          Enabled: item.Enabled,
          Emails: item.Receivers.filter(i => i.MessageProvider === 0).map(i => ({ label: i.Address })),
          PandayoPlusUrls: item.Receivers.filter(i => i.MessageProvider === 2).map(i => ({ label: i.Address })),
          AmlRules: item.Rule.AmlRgRule.filter(i => i.GamingControl === GamingControlEnum.aml).map(i => ({ Id: i.AmlRuleType, Name: i.AmlRuleName })),
          RgRules: item.Rule.AmlRgRule.filter(i => i.GamingControl === GamingControlEnum.rg).map(i => ({ Id: i.AmlRuleType, Name: i.AmlRuleName })),
          SingleThresholdRule: item.Rule.SingleThresholdRule.map(i => ({ Id: i.AmlRuleType, Name: i.AmlRuleName })),
          RiskAssessmentRule: item.Rule.RiskAssessmentRule ? item.Rule.RiskAssessmentRule.map(i => ({ Id: i.AmlRuleType, Name: i.AmlRuleName })) : []
        });
      });
      newData.NotificationSettingsTemplates = notificationSettingsTemplates;
    }
    if (newData.LicenseRuleTemplate?.SingleRules) {
      newData.LicenseRuleTemplate.SingleRules.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.PaymentSystemIds = (item.PaymentSystemIds || []).map(i => ({ Id: i }));
      });
    }
    if (newData.LicenseRuleTemplate?.MultipleRules) {
      newData.LicenseRuleTemplate.MultipleRules.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.PaymentSystemIds = (item.PaymentSystemIds || []).map(i => ({ Id: i }));
      });
    }
  }
  return newData;
};

const parseAmlData = (response: Object) => {
  const { Data } = response;
  if (Data) {
    if (Data?.LicenseRuleTemplate?.Rules) {
      Data.LicenseRuleTemplate.Rules.forEach((item: Object) => {
        const newRuleConfig = [];
        const currentRules = item.RuleConfigs && item.RuleConfigs.length && item.RuleConfigs || [];
        RuleConfigs.forEach(i => {
          const hasConfig = currentRules.find(config => config.RiskCategory === i.id);
          newRuleConfig.push(hasConfig ? { ...hasConfig, isActive: true } : RuleConfigInitialData(i, item));
        });
        // eslint-disable-next-line no-param-reassign
        item.RuleConfigs = newRuleConfig;
      });
    }

    if (Data?.ScoreRuleTemplate?.ScoreCategorySettings) {
      const newRuleConfig = [];
      const currentRules = Data?.ScoreRuleTemplate.ScoreCategorySettings || [];
      RuleConfigs.forEach(i => {
        const hasConfig = currentRules.find(config => config.RiskClasifiation === i.id);
        newRuleConfig.push(hasConfig ? { ...hasConfig, IsActive: true } : ScoreRuleCategoryInitialData(i));
      });
      Data.ScoreRuleTemplate.ScoreCategorySettings = newRuleConfig;
    }

    if (Data?.LicenseRuleTemplate?.AmlRuleScoreEnabled && Data?.LicenseRuleTemplate?.AmlRuleScoreSettings) {
      const newRuleConfig = [];
      const currentRules = Data?.LicenseRuleTemplate?.AmlRuleScoreSettings || [];
      RuleConfigs.forEach(i => {
        const hasConfig = currentRules.find(config => config.RiskClasifiation === i.id);
        newRuleConfig.push(hasConfig ? { ...hasConfig, IsActive: true } : AmlRuleScoreRuleCategoryInitialData(i));
      });
      Data.LicenseRuleTemplate.AmlRuleScoreSettings = newRuleConfig;
    }

    if (Data?.LicenseRuleTemplate?.RgRuleScoreEnabled && Data?.LicenseRuleTemplate?.RgRuleScoreSettings) {
      const newRuleConfig = [];
      const currentRules = Data?.LicenseRuleTemplate?.RgRuleScoreSettings || [];
      RuleConfigs.forEach(i => {
        const hasConfig = currentRules.find(config => config.RiskClasifiation === i.id);
        newRuleConfig.push(hasConfig ? { ...hasConfig, IsActive: true } : RgRuleScoreRuleCategoryInitialData(i));
      });
      Data.LicenseRuleTemplate.RgRuleScoreSettings = newRuleConfig;
    }
  }
  return Data;
};

export default parseAmlData;
