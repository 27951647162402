/**
 * @flow
 * */
import { takeLatest, put } from 'redux-saga/effects';

import FRMT_SETTINGS_TYPES from 'modules/FRMT/Settings/store/actions/settingsActions/types';
import {
  alertSettingsFailure,
  alertSettingsSuccess,
  saveAlertSettingsFailure,
  saveAlertSettingsSuccess
} from 'modules/FRMT/Settings/store/actions/settingsActions';
import settingSdk from 'modules/FRMT/Settings/core/sdk';

class SettingsController {
  static* fetchAlertSettings() {
    try {
      const { data } = yield settingSdk.fetchAlertSettings();
      yield put(alertSettingsSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(alertSettingsFailure({ error: err.message }));
    }
  }

  static* saveAlertSettings({ payload }) {
    const { params } = payload;
    try {
      const { data } = yield settingSdk.saveAlertSettings({ params });
      yield put(saveAlertSettingsSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(saveAlertSettingsFailure({ error: err.message }));
    }
  }

  static* cancelSettingsSDKCalls({ payload }) {
    const { name } = payload;
    yield settingSdk.cancelSettingsSDKCalls({ name });
  }

  static* actionsWatcher(): Object {
    yield takeLatest(FRMT_SETTINGS_TYPES.CANCEL_FRMT_SETTINGS_CALL, SettingsController.cancelSettingsSDKCalls);
    yield takeLatest(FRMT_SETTINGS_TYPES.FETCH_ALERT_SETTINGS, SettingsController.fetchAlertSettings);
    yield takeLatest(FRMT_SETTINGS_TYPES.SAVE_ALERT_SETTINGS, SettingsController.saveAlertSettings);
  }
}

export default SettingsController.actionsWatcher;
