export const keysForAdditionalSettings = [
  { key: 'Women' },
  { key: 'ExcludeGroupIds' },
  { key: 'SportsBookCategories' },
  { initialKey: 'Ages', key: 'Age', normalizeKey: 'Id' },
  { key: 'Country',
    groupKeys: [
      { key: 'Countries', normalizeKey: 'Id' },
      { key: 'CountryList' }
    ] },
  { key: 'Currency',
    groupKeys: [
      { key: 'Currencies', normalizeKey: 'Id' },
      { key: 'AllCurrencies' }
    ] },
  { initialKey: 'Cities', key: 'City', normalizeKey: 'Id' },
  { initialKey: 'Fingerprints', key: 'Fingerprint', normalizeKey: 'Id' },
  { initialKey: 'DangerousIPs', key: 'DangerousIP', normalizeKey: 'Id' },
  { initialKey: 'AffilateIdBTags', key: 'AffilateIdBTag', normalizeKey: 'Id' },
  { key: 'PreDefinedIps' }
];

const forUpdate = ({ payload }) => {
  if (payload.keyForEdit) {
    return {
      [payload.data[payload.keyForEdit]]: payload.data
    };
  }
  return payload.data;
};

export const generateObjectByActionType = ({ data, payload }) => {
  if (payload.forDelete) {
    if (payload.isSubKey) {
      const obj = data[payload.subKey];
      delete obj[payload.forDelete];
      return {
        ...data,
        [payload.subKey]: obj
      };
    }
    const obj = data;
    delete obj[payload.forDelete];
    return obj;
  }
  return payload.isSubKey ? {
    ...data,
    [payload.subKey]: {
      ...data[payload.subKey],
      [payload.data.Id]: payload.data
    }
  } : {
    ...data,
    ...forUpdate({ payload })
  };
};
