import { setFetchPlayerInfoFailure, setFetchPlayerInfoSuccess } from 'modules/LCRMT/Players/store/actions/player/generalActions';
import { parsePlayersModelFromResponse, parseSRMTPlayerMultiAccountResponse } from 'modules/SRMT/Players/utils/parsers';
import PLAYER_ACTION_TYPES from 'modules/LCRMT/Players/store/actions/player/types';
import playersSDK from 'modules/LCRMT/Players/core/sdk/players';
import { put, takeLatest } from 'redux-saga/effects';
import {
  setFetchAddLiveGamesRestrictionsFailure, setFetchDeleteLiveGamesRestrictionsSuccess,
  setFetchLiveGamesRestrictionsFailure, setFetchAddLiveGamesRestrictionsSuccess,
  setFetchDeleteLiveGamesRestrictionsFailure, setFetchTablesByCategorySuccess,
  setFetchTablesByCategoryFailure, setFetchGameCategoriesSuccess,
  setFetchHourlyActivityFailure, setFetchHourlyActivitySuccess,
  setFetchPlayersSuccess, setFetchLiveGamesRestrictionsSuccess,
  setFetchGameCategoriesFailure, setFetchPlayersAlertsSuccess,
  setFetchPlayersAlertsFailure, setFetchMultiAccountsFailure,
  setFetchGlobalInfoFailure, setFetchGlobalInfoSuccess,
  setFetchGlobalKPIsFailure, setFetchGlobalKPIsSuccess,
  setFetchPlayersAppendSuccess, setFetchPlayersFailure,
  setFetchMultiAccountsSuccess
} from 'modules/LCRMT/Players/store/actions/player';
import {
  fetchLCRMTPlayerActivitySessionStatisticsSucceed,
  fetchLCRMTPlayerActivitySessionStatisticsFailed,
  fetchLCRMTPlayerActivitySessionViewSucceed,
  fetchLCRMTPlayerActivitySessionViewFailed,
  setFetchActivitySessionViewExpandSuccess,
  setFetchActivitySessionViewExpandFailure,
  fetchLCRMTPlayerActivitySessionSucceed,
  fetchLCRMTPlayerActivitySessionFailed
} from 'modules/LCRMT/Players/store/actions/player/activityHistoryActions';
import {
  fetchLCRMTPlayerFinancialTransactionsTableSucceed,
  fetchLCRMTPlayerFinancialTransactionsTableFailed,
  fetchLCRMTPlayerFinancialTransactionsKPISucceed,
  fetchLCRMTPlayerFinancialTransactionsKPIFailed,
  fetchLCRMTPlayerFinancialGamingTableSucceed,
  fetchLCRMTPlayerFinancialGamingTableFailed,
  fetchLCRMTPlayerFinancialGamingKPISucceed,
  fetchLCRMTPlayerFinancialGamingKPIFailed,
  fetchLCRMTPlayerFinancialActivitySucceed,
  fetchLCRMTPlayerFinancialActivityFailed
} from 'modules/LCRMT/Players/store/actions/player/financialActions';

class PlayerController {
  static* fetchGlobalInfo({ payload }) {
    const { playerId } = payload;
    try {
      const { data } = yield playersSDK.getGlobalInfo({ playerId });
      yield put(setFetchGlobalInfoSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchGlobalInfoFailure({ error: err.message, errorStatusCode: err.res.body.status }));
    }
  }

  static* fetchLiveGamesRestrictions({ payload }) {
    const { playerId, partnerId } = payload;
    try {
      const { data } = yield playersSDK.getLiveGamesRestrictions({ playerId, partnerId });
      yield put(setFetchLiveGamesRestrictionsSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchLiveGamesRestrictionsFailure({ error: err.message }));
    }
  }

  static* fetchAddLiveGamesRestrictions({ payload }) {
    const { ...params } = payload;
    try {
      const response = yield playersSDK.addLiveGamesRestrictions({ ...params });
      yield put(setFetchAddLiveGamesRestrictionsSuccess({ response }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchAddLiveGamesRestrictionsFailure({ error: err.message }));
    }
  }

  static* fetchDeleteLiveGamesRestrictions({ payload }) {
    try {
      const response = yield playersSDK.deleteLiveGamesRestrictions(payload);
      yield put(setFetchDeleteLiveGamesRestrictionsSuccess({ response }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchDeleteLiveGamesRestrictionsFailure({ error: err.message }));
    }
  }

  static* fetchTablesByCategory({ payload }) {
    const { categoryId } = payload;
    try {
      const data = yield playersSDK.getTablesByCategory({ categoryId });
      yield put(setFetchTablesByCategorySuccess(data));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchTablesByCategoryFailure({ error: err.message }));
    }
  }

  static* fetchGameCategories({ payload }) {
    const { partnerId } = payload;
    try {
      const data = yield playersSDK.getGameCategories({ partnerId });
      yield put(setFetchGameCategoriesSuccess(data));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchGameCategoriesFailure({ error: err.message }));
    }
  }

  static* fetchHourlyActivity({ payload }) {
    const { playerId, dateFrom, dateTo } = payload;
    try {
      const { data } = yield playersSDK.getHourlyActivity({ playerId, dateFrom, dateTo });
      yield put(setFetchHourlyActivitySuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchHourlyActivityFailure({ error: err.message }));
    }
  }

  static* fetchGlobalKPIs({ payload }) {
    const { playerId, isEURCurrencyEnabled } = payload;
    try {
      const { data } = yield playersSDK.getGlobalKPIs({ playerId, isEURCurrencyEnabled });
      yield put(setFetchGlobalKPIsSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchGlobalKPIsFailure({ error: err.message }));
    }
  }

  static* fetchPlayerInfo({ payload }) {
    const { id } = payload;
    try {
      const { data } = yield playersSDK.getPlayerInfo({ id });
      yield put(setFetchPlayerInfoSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchPlayerInfoFailure({ error: err.message }));
    }
  }

  static* fetchLCRMTPlayers({ payload }) {
    const { passTotalCount } = payload;
    try {
      // eslint-disable-next-line no-param-reassign
      !!payload.autofetch && delete payload.autofetch;
      const response = yield playersSDK.getPlayers(payload);
      const { data, totalCount } = parsePlayersModelFromResponse(response);
      if (payload.appendData) {
        yield put(setFetchPlayersAppendSuccess({ data, totalCount }));
      } else {
        yield put(setFetchPlayersSuccess({ data, totalCount, passTotalCount }));
      }
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchPlayersFailure({ error: err.message }));
    }
  }

  static* fetchPlayerActivitySession({ payload }) {
    try {
      const { data } = yield playersSDK.getActivitySession(payload);
      yield put(fetchLCRMTPlayerActivitySessionSucceed({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(fetchLCRMTPlayerActivitySessionFailed({ error: err.message }));
    }
  }

  static* fetchPlayerActivitySessionView({ payload }) {
    try {
      const { data } = yield playersSDK.getActivitySessionView(payload);
      yield put(fetchLCRMTPlayerActivitySessionViewSucceed({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(fetchLCRMTPlayerActivitySessionViewFailed({ error: err.message }));
    }
  }

  static* fetchPlayerActivitySessionViewExpand({ payload }) {
    const { roundId, tableId, clientId, index } = payload;
    try {
      const { data } = yield playersSDK.getActivitySessionViewExpand({ roundId, tableId, clientId });
      yield put(setFetchActivitySessionViewExpandSuccess({ data, index }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchActivitySessionViewExpandFailure({ error: err.message }));
    }
  }

  static* fetchPlayerActivitySessionStatistics({ payload }) {
    const { statisticType } = payload;
    try {
      const { data } = yield playersSDK.getActivitySessionStatistics(payload);
      yield put(fetchLCRMTPlayerActivitySessionStatisticsSucceed({ data, statisticType }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(fetchLCRMTPlayerActivitySessionStatisticsFailed({ error: err.message, statisticType }));
    }
  }

  static* fetchPlayersAlerts({ payload }) {
    const { playerId } = payload;
    try {
      const { data } = yield playersSDK.getPlayersAlerts({ playerId });
      yield put(setFetchPlayersAlertsSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchPlayersAlertsFailure({ error: err.message }));
    }
  }

  static* fetchPlayerFinancialGamingKPI({ payload }) {
    try {
      const { data } = yield playersSDK.getPlayerFinancialGamingKPI({ ...payload });
      yield put(fetchLCRMTPlayerFinancialGamingKPISucceed({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(fetchLCRMTPlayerFinancialGamingKPIFailed({ error: err.message }));
    }
  }

  static* fetchPlayerFinancialGamingTable({ payload }) {
    try {
      const { data } = yield playersSDK.getPlayerFinancialGamingTable({ ...payload });
      yield put(fetchLCRMTPlayerFinancialGamingTableSucceed({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(fetchLCRMTPlayerFinancialGamingTableFailed({ error: err.message }));
    }
  }

  static* fetchPlayerFinancialActivity({ payload }) {
    try {
      const { data } = yield playersSDK.getPlayerFinancialActivity({ ...payload });
      yield put(fetchLCRMTPlayerFinancialActivitySucceed({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(fetchLCRMTPlayerFinancialActivityFailed({ error: err.message }));
    }
  }

  static* fetchPlayerFinancialTransactionsKPI({ payload }) {
    try {
      const { data } = yield playersSDK.getPlayerFinancialTransactionsKPI({ ...payload });
      yield put(fetchLCRMTPlayerFinancialTransactionsKPISucceed({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(fetchLCRMTPlayerFinancialTransactionsKPIFailed({ error: err.message }));
    }
  }

  static* fetchPlayerFinancialTransactionsTable({ payload }) {
    try {
      const { data } = yield playersSDK.getPlayerFinancialTransactionsTable({ ...payload });
      yield put(fetchLCRMTPlayerFinancialTransactionsTableSucceed({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(fetchLCRMTPlayerFinancialTransactionsTableFailed({ error: err.message }));
    }
  }

  static* fetchMultiAccounts({ payload }) {
    const { playerId, count, start } = payload;
    try {
      const response = yield playersSDK.getLCRMTMultiAccounts({ playerId, count, start });
      // eslint-disable-next-line prefer-const
      const { data } = parseSRMTPlayerMultiAccountResponse(response);
      yield put(setFetchMultiAccountsSuccess({ data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchMultiAccountsFailure({ error: err.message }));
    }
  }

  static* cancelPlayerSDKCalls({ payload }) {
    const { name } = payload;
    yield playersSDK.cancelPlayerSDKCalls({ name });
  }

  static* playerWatcher<T>(): Iterable<T> {
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_LCRMT_PLAYERS, PlayerController.fetchLCRMTPlayers);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_GLOBAL_INFO_LCRMT, PlayerController.fetchGlobalInfo);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_HOURLY_ACTIVITY_LCRMT, PlayerController.fetchHourlyActivity);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_GLOBAL_KPIS_LCRMT, PlayerController.fetchGlobalKPIs);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_PLAYER_INFO_LCRMT, PlayerController.fetchPlayerInfo);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_LIVE_GAMES_RESTRICTIONS, PlayerController.fetchLiveGamesRestrictions);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_ADD_LIVE_GAMES_RESTRICTIONS, PlayerController.fetchAddLiveGamesRestrictions);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_DELETE_LIVE_GAMES_RESTRICTIONS, PlayerController.fetchDeleteLiveGamesRestrictions);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_TABLES_BY_CATEGORY, PlayerController.fetchTablesByCategory);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_GAME_CATEGORIES, PlayerController.fetchGameCategories);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_ACTIVITY_SESSION, PlayerController.fetchPlayerActivitySession);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_ACTIVITY_SESSION_VIEW, PlayerController.fetchPlayerActivitySessionView);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_ACTIVITY_SESSION_VIEW_EXPAND, PlayerController.fetchPlayerActivitySessionViewExpand);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_ACTIVITY_SESSION_STATISTICS, PlayerController.fetchPlayerActivitySessionStatistics);
    yield takeLatest(PLAYER_ACTION_TYPES.CANCEL_LCRMT_PLAYER_SDK_CALL, PlayerController.cancelPlayerSDKCalls);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_LCRMT_PLAYERS_ALERTS, PlayerController.fetchPlayersAlerts);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_LCRMT_PLAYER_FINANCIAL_GAMING_KPI, PlayerController.fetchPlayerFinancialGamingKPI);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_LCRMT_PLAYER_FINANCIAL_GAMING_TABLE, PlayerController.fetchPlayerFinancialGamingTable);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_LCRMT_PLAYER_FINANCIAL_ACTIVITY, PlayerController.fetchPlayerFinancialActivity);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_KPI, PlayerController.fetchPlayerFinancialTransactionsKPI);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_LCRMT_PLAYER_FINANCIAL_TRANSACTIONS_TABLE, PlayerController.fetchPlayerFinancialTransactionsTable);
    yield takeLatest(PLAYER_ACTION_TYPES.FETCH_LCRMT_MULTI_ACCOUNTS, PlayerController.fetchMultiAccounts);
  }
}

export default PlayerController.playerWatcher;
