/**
 * @flow
 * */
import React, { useMemo } from 'react';

import { licenseIds } from 'modules/AML/utils/licenseIds';
import { useLocation } from 'react-router-dom';
import MenuBar from 'views/components/MenuBar';
import Link from 'react-router-dom/es/Link';
import logo from 'assets/icons/logo.svg';
import settings from 'config/constants';

import HeaderUser from './HeaderUser';

import './styles.scss';

type Props = {
  user: Object
}
function Header({ user }: Props) {
  const location = useLocation();
  const hasMenu = useMemo(() => {
    const products = settings.PRODUCTS;
    const { pathname } = location;
    // $FlowFixMe
    return pathname.includes(products.ADMIN.path) || pathname.includes(products.RMT.path) || pathname.includes(products.FRMT.path)
        || pathname.includes(products.CRMT.path) || pathname.includes(products.LCRMT.path) || Object.keys(licenseIds).some(i => pathname.includes(i));
  }, [location]);

  return (
    <div className="header flex f-jsb header-fixed">
      <div className="h-sides left">
        <div className="h-sides-content left-content">
          <div role="button" tabIndex={0} className="h-logo-link">
            <Link to="/">
              <img className="h-logo" src={logo} alt="BetConstruct logo" title="BetConstruct" />
            </Link>
          </div>
          {/* // $FlowFixMe */}
          {hasMenu && <MenuBar />}
        </div>
      </div>
      <div className="h-sides right">
        <div className="h-sides-content flex f-jc f-ac">
          <HeaderUser user={user} />
        </div>
      </div>
    </div>
  );
}

export default Header;
