export const pdfConfig = {
  name: 'DB',
  version: 1,
  objectStoresMeta: [
    {
      store: 'pdf',
      storeConfig: { keyPath: 'name', autoIncrement: false },
      storeSchema: []
    },
    {
      store: 'game',
      storeConfig: { keyPath: 'name', autoIncrement: false },
      storeSchema: []
    }
  ]
};
