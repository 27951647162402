import PLAYER_ACTIONS from 'modules/LCRMT/Players/store/actions/player/types';
import type { Action } from 'lib/core/flow';

// Activity Session
export const fetchLCRMTPlayerActivitySession = ({ playerId, filter }):Action => ({
  type: PLAYER_ACTIONS.FETCH_ACTIVITY_SESSION,
  payload: { playerId, filter }
});
export const fetchLCRMTPlayerActivitySessionSucceed = ({ data }):Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_ACTIVITY_SESSION_SUCCESS,
  payload: { data }
});
export const fetchLCRMTPlayerActivitySessionFailed = ({ error }):Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_ACTIVITY_SESSION_FAILURE,
  payload: { error }
});
export const clearLCRMTPlayerActivitySession = ():Action => ({
  type: PLAYER_ACTIONS.CLEAR_ACTIVITY_SESSION_STATE,
  payload: {}
});

// Activity Session View
export const fetchLCRMTPlayerActivitySessionView = ({ playerId, sessions }):Action => ({
  type: PLAYER_ACTIONS.FETCH_ACTIVITY_SESSION_VIEW,
  payload: { playerId, sessions }
});
export const fetchLCRMTPlayerActivitySessionViewSucceed = ({ data }):Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_ACTIVITY_SESSION_VIEW_SUCCESS,
  payload: { data }
});
export const fetchLCRMTPlayerActivitySessionViewFailed = ({ error }):Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_ACTIVITY_SESSION_VIEW_FAILURE,
  payload: { error }
});
export const ClearLCRMTPlayerActivitySessionView = ():Action => ({
  type: PLAYER_ACTIONS.CLEAR_ACTIVITY_SESSION_VIEW_STATE,
  payload: { }
});

// Activity Session View Expand
export const fetchLCRMTPlayerActivitySessionViewExpand = ({ roundId, tableId, clientId, index }: Object): Action => ({
  type: PLAYER_ACTIONS.FETCH_ACTIVITY_SESSION_VIEW_EXPAND,
  payload: { roundId, tableId, clientId, index }
});
export const setFetchActivitySessionViewExpandSuccess = ({ data, index }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_ACTIVITY_SESSION_VIEW_EXPAND_SUCCESS,
  payload: { data, index }
});
export const setFetchActivitySessionViewExpandFailure = ({ index, error }: Object): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_ACTIVITY_SESSION_VIEW_EXPAND_FAILURE,
  payload: { index, error }
});

// Activity Session Statistics
export const fetchLCRMTPlayerActivitySessionStatistics = ({ clientAGPId, dates, statisticType }):Action => ({
  type: PLAYER_ACTIONS.FETCH_ACTIVITY_SESSION_STATISTICS,
  payload: { clientAGPId, dates, statisticType }
});
export const fetchLCRMTPlayerActivitySessionStatisticsSucceed = ({ data, statisticType }):Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_ACTIVITY_SESSION_STATISTICS_SUCCESS,
  payload: { data, statisticType }
});
export const fetchLCRMTPlayerActivitySessionStatisticsFailed = ({ error, statisticType }):Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_ACTIVITY_SESSION_STATISTICS_FAILURE,
  payload: { error, statisticType }
});
export const ClearLCRMTPlayerActivitySessionStatistics = ():Action => ({
  type: PLAYER_ACTIONS.CLEAR_ACTIVITY_SESSION_STATISTICS_STATE,
  payload: { }
});
